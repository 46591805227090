import React from 'react';
import "./FooterPage.css"
import {Link as LinkRouterDom} from "react-router-dom";

function FooterPage(props) {
    return (
        <section id="footer">
            <div className="container">
                <div className="row text-center text-xs-center text-sm-left text-md-left">
                    <div className="col-xs-12 col-sm-4 col-md-6">
                        <h5>Kontaktní údaje</h5>
                        <ul className="list-unstyled quick-links">
                            <li><a href="tel:+420 608 597 418<"><i className="fa fa-angle-double-right"></i>+420 608 597 418</a></li>
                            <li><a href="mailto:roubenka-maruska@seznam.cz"><i className="fa fa-angle-double-right"></i>roubenka-maruska@seznam.cz</a></li>
                            <li><a href="https://www.google.com/maps/place/Komorn%C3%AD+Lhotka+13,+739+53+Komorn%C3%AD+Lhotka/@49.6564457,18.5275929,19.45z/data=!4m15!1m8!3m7!1s0x471408c18d7047cb:0xe7fdb3c044af90bd!2sKomorn%C3%AD+Lhotka+13,+739+53+Komorn%C3%AD+Lhotka!3b1!8m2!3d49.6565131!4d18.5276689!16s%2Fg%2F11c1b2rc91!3m5!1s0x471408c18d7047cb:0xe7fdb3c044af90bd!8m2!3d49.6565131!4d18.5276689!16s%2Fg%2F11c1b2rc91?entry=ttu"><i className="fa fa-angle-double-right"></i>Komorní Lhotka 13</a></li>
                        </ul>
                    </div>
                    <div className="col-xs-12 col-sm-4 col-md-6">
                        <h5>Rychlé menu</h5>
                        <ul className="list-unstyled quick-links">
                            <li><a href="/#information-page"><i className="fa fa-angle-double-right"></i>Informace</a></li>
                            <li><a href="/#interesting-facts-page"><i className="fa fa-angle-double-right"></i>Zajímavosti</a></li>
                            <li><a href="/#rezervation-page"><i className="fa fa-angle-double-right"></i>Rezervace</a></li>
                            <li><a href="/#pricing-page"><i className="fa fa-angle-double-right"></i>Ceník</a></li>
                            <li><a href="/#gallery-page"><i className="fa fa-angle-double-right"></i>Galerie</a></li>
                        </ul>
                    </div>
                </div>
                <div className="row">
                    <div className="col-xs-12 col-sm-12 col-md-12 mt-2 mt-sm-5">
                        <ul className="list-unstyled list-inline social text-center">
                            <li className="list-inline-item"><a href="/"><i
                                className="fa fa-facebook"></i></a>
                            </li>
                            <li className="list-inline-item"><a href="/"><i
                                className="fa fa-instagram"></i></a></li>
                        </ul>
                    </div>
                </div>
                <div className="row">
                    <div className="w-100 d-flex justify-content-center align-items-center" style={{height: "90%"}}>
                        <iframe className="google-map-image" title={"mapa"}
                                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d473.51563377857775!2d18.52759286675954!3d49.65644570233678!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x471408c18d7047cb%3A0xe7fdb3c044af90bd!2sKomorn%C3%AD%20Lhotka%2013%2C%20739%2053%20Komorn%C3%AD%20Lhotka!5e0!3m2!1sen!2scz!4v1716620786551!5m2!1sen!2scz"
                                height="250" allowFullScreen="" loading="lazy"
                                referrerPolicy="no-referrer-when-downgrade" style={{width:"90%"}}></iframe>
                    </div>
                    <div className="col-xs-12 col-sm-12 col-md-12 mt-2 mt-sm-2 text-center text-white">
                        <a className="text-green ml-2" href="https://www.dominikwojnar.cz" target="_blank" rel="noreferrer" >Vypracoval: <strong> | </strong> <strong>Dominik Wojnar</strong></a>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default FooterPage;

