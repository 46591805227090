import React, {useEffect, useMemo, useState} from 'react';
import Calendar from 'react-calendar'
import 'react-calendar/dist/Calendar.css';
import "./RezervationPage.css"
import "../PageStyle.css"
import ConfirmationModal from "../../components/Modals/ConfirmationModal";
import {convertJsDateToPostgresFormat, formattedDate, addYears, CZKCurrency, mostFrequent} from "../../utils/helper"

import axios from "axios";
import Swal from "sweetalert2";
import AvailableColorBox from "./Boxes-Indicators/AvailableColorBox";
import UnAvailableColorBox from "./Boxes-Indicators/UnAvailableColorBox";
import EventColorBox from "./Boxes-Indicators/EventColorBox";

function RezervationPage() {
    const [showButton, setShowButton] = useState(false);
    const [show, setShow] = useState(false);
    const handleClose = () => {
        setShow(false);
        setDate([]);
    }
    const handleShow = () => setShow(true);

    const [selectedDaysCount, setSelectedDasCount] = useState(0);
    const [eventId, setEventId] = useState(0);
    const handleDateChange = (newDate) => {
        const startDate = new Date(newDate[0]);


        startDate.setHours(0, 0, 0, 0);
        const endDate = new Date(newDate[1]);
        endDate.setHours(0, 0, 0, 0);

        setDate([startDate, endDate]);

        let time_difference = endDate.getTime() - startDate.getTime();
        let nights_difference = time_difference / (1000 * 60 * 60 * 24);

        // Datumy nesmi byt stejne - minimalne na jednu noc rezervace pro chatu nebo minimalni pocet noci, ktery nastavil admin
        if ((startDate.getDate() === endDate.getDate())
            || nights_difference < defaultMonthLabel?.min_day_rent_length) {
            Swal.fire(
                `Minimálně ${defaultMonthLabel?.min_day_rent_length} noc/nocí `,
                `Minimální délka klasické rezervace pro tento měsíc je na <h5 class="font-weight-bold">${defaultMonthLabel?.min_day_rent_length} noc/nocí </h4>`,
                'error'
            )
            setDate([])
            setSelectedDasCount(0);
            return
        }
        setEventId(0)

        const dateEventsArr = calendarDates.filter((dateCalendar) => {
            if (dateCalendar.date <= convertJsDateToPostgresFormat(endDate) && dateCalendar.date >= convertJsDateToPostgresFormat(startDate)) {
                if (dateCalendar.eventId !== null) {
                    return dateCalendar
                }
            }
        })

        const dateEventId = parseInt(mostFrequent(dateEventsArr, x => x.eventId) ?? null);
        const res = calendarDates.filter((dateCalendar) => dateCalendar.eventId === dateEventId).sort((a, b) => {
            return new Date(a.date) - new Date(b.date);
        });


        ////////
        const getStartEndRangeDates = (data, startDate, endDate) => {
            return data.filter(item => {
                const itemDate = item.date
                return itemDate >= startDate && itemDate <= endDate;
            });
        };

        // event ID cannot be same - length of EVENT ID count must be 2
        // at least one date with event date null
        const startEndRanges = getStartEndRangeDates(calendarDates, convertJsDateToPostgresFormat(startDate), convertJsDateToPostgresFormat(endDate))
        const noEventDate = startEndRanges.filter(item => item.eventId === null).length
        if (noEventDate !== 0) {
            const firstEventId = startEndRanges.filter(item => item.eventId !== null)
            if (firstEventId.length <= 2 && firstEventId.length >= 1) {
                const secondEventId = startEndRanges.filter(item => item.eventId !== firstEventId[0].eventId && item.eventId !== null)
                if (secondEventId.length) {
                    handleShow()
                    return
                }
            }
            if (firstEventId.length === 1) {
                handleShow()
                return
            }
        }
        ////

        if (dateEventId) {
            setEventId(dateEventId)
        }
        if (res.length !== 0) {
            if ((convertJsDateToPostgresFormat(startDate) !== res[0].date || convertJsDateToPostgresFormat(endDate) !== res[res.length - 1].date)) {
                if (new Date(res[0].date) >= new Date()) {
                    setShow(false)
                    // Handle pop up confirmation window
                    Swal.fire({
                        title: 'Toto je speciální datum!',
                        html: `Musíte vybrat všechny termíny 
                        <h5 class="font-weight-bold">od ${formattedDate(res[0].date)} až ${formattedDate(res[res.length - 1].date)} </h5> 
                        jinak rezervace nepůjde provést
                        <h3>Chcete nastavit potřebné termíny pro tuto rezervaci ? </h3>`,
                        showCancelButton: true,
                        cancelButtonText: "Zrušit",
                        confirmButtonText: "Ano chci nastavit",
                        icon: 'error'
                    }).then((result) => {
                        if (result.isConfirmed) {
                            const firstEventDate = new Date(res[0].date);
                            firstEventDate.setHours(0, 0, 0, 0);
                            const lastEventDate = new Date(res[res.length - 1].date)
                            lastEventDate.setHours(0, 0, 0, 0);

                            setDate([firstEventDate, lastEventDate])
                            setShow(true)
                        } else {
                            setDate([])
                            setSelectedDasCount(0)
                        }
                    })
                    return
                }
            } else if (new Date(res[0].date) < new Date()) {
                Swal.fire({
                    title: 'Nelze rezervovat',
                    html: `Je nám líto, tento speciální termín již nelze rezervovat, jeho platnost vypršela`,
                    showCancelButton: true,
                    cancelButtonText: "Zrušit",
                    icon: 'error'
                })
                return
            }
        }
        // Open pop up confirmation window
        handleShow()

    };

    // get reservations from DB
    const [cottageReservations, setCottageReservations] = useState([]);
    const [mainCottageId, setMainCottageId] = useState([]);
    const [lastAvailableDate, setLastAvailableDate] = useState(null)
    const [loadingCalendar, setLoadingCalendar] = useState(false) // loading calendar
    const getAllCottageReservations = async () => {
        try {
            setLoadingCalendar(true);
            let mainCottageFromDB = await axios.get(`${process.env.REACT_APP_SERVER_URL}/api/cottage/main-cottage`)
            setLastAvailableDate(addYears(new Date(), mainCottageFromDB.data.mainCottage[0].reservation_years_length))
            let mainCottageIdFromDB = mainCottageFromDB.data.mainCottage[0].cottage_id
            const response = await axios.get(`${process.env.REACT_APP_SERVER_URL}/api/reservations/cottage/${mainCottageIdFromDB}/all-reservations`);
            setCottageReservations(response.data.cottageReservations);
            setMainCottageId(mainCottageIdFromDB)
        } catch (error) {
            console.error('Error while fetching data:', error);
        } finally {
            setLoadingCalendar(false)
        }
    }

    // Get Calendar Setted Default or Action dates and prices - SETTED BY ADMIN
    const [calendarDates, setCalendarDates] = useState([]);
    const getAllCalendarDates = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_SERVER_URL}/api/calendar/all-dates`);
            setCalendarDates(response.data.data);
        } catch (error) {
            console.error('Error while fetching data:', error);
        }
    }

    const [availableEventDates, setAvailableEventsDates] = useState([])
    const getAllAvailableEventsDates = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_SERVER_URL}/api/calendar/all-available-events-dates`);

            setAvailableEventsDates(response.data.data);
        } catch (error) {
            console.error('Error while fetching data:', error);
        }
    }

    useEffect(() => {
        getAllCottageReservations();
        getAllCalendarDates();
        getAllAvailableEventsDates()
    }, []);

    // Display free dates and unavailable dates from calendar
    const [date, setDate] = useState([]);
    const [rezervated, setRezervated] = useState(true);

    const [defaultMonthLabel, setDefaultMonthLabel] = useState(null)
    const tileClassName = useMemo(() => {
        return ({date}) => {
            const postgresDate = convertJsDateToPostgresFormat(date);

            if (date <= new Date()) {
                return 'gone-date'
            }

            // Find events for the current month
            const calendarDate = calendarDates.find((dateCalendar) =>
                new Date(dateCalendar.date).getMonth() + 1 === date.getMonth() + 1 &&
                new Date(dateCalendar.date).getFullYear() === date.getFullYear() &&
                dateCalendar.eventId === null
            );
            setDefaultMonthLabel(calendarDate); // Set DefaultMonth label

            const unavailableDates = cottageReservations.find(reservation =>
                postgresDate >= convertJsDateToPostgresFormat(reservation.UserReservation.start_date).substring(0, 10) && // tato 10 je, ze nam odstrani cas od 10 znaku - napr z 2023-02-02T10:00:00 - udela 2023-02-02
                postgresDate <= convertJsDateToPostgresFormat(reservation.UserReservation.end_date).substring(0, 10) // tato 10 je, ze nam odstrani cas od 10 znaku - napr z 2023-02-02T10:00:00 - udela 2023-02-02
            );

            const isEventStart = availableEventDates.find(event =>
                event.start_date === postgresDate
            );
            const isEventEnd = availableEventDates.find(event =>
                event.end_date === postgresDate
            );

            if (unavailableDates) {
                if (isEventStart) {
                    if (convertJsDateToPostgresFormat(unavailableDates.UserReservation.end_date) === postgresDate && isEventStart.start_date === postgresDate) {
                        if (isEventStart.event_color === 1) {
                            return "red-purple-light "
                        } else {
                            return "red-purple-dark"
                        }
                    }
                }
                if (isEventEnd) {
                    if (isEventEnd.end_date === postgresDate && convertJsDateToPostgresFormat(unavailableDates.UserReservation.start_date) === postgresDate) {

                        if (isEventEnd.event_color === 1) {
                            return "purple-red-light"
                        } else {
                            return "purple-red-dark"
                        }
                    }
                }
                // cross available and unavailable
                if(cottageReservations.find(reservation => convertJsDateToPostgresFormat(reservation.UserReservation.start_date) === postgresDate) && cottageReservations.find(reservation => convertJsDateToPostgresFormat(reservation.UserReservation.end_date) === postgresDate)){
                    return "unavailable-date"
                }
                if(cottageReservations.find(reservation => convertJsDateToPostgresFormat(reservation.UserReservation.start_date) === postgresDate)){
                    return "green-red"
                }
                if(cottageReservations.find(reservation => convertJsDateToPostgresFormat(reservation.UserReservation.end_date) === postgresDate)){
                    return "red-green"
                }
                return "unavailable-date";
            } else {
                const isEvent = availableEventDates.find(event =>
                    event.start_date && event.event_id !== null && event.start_date <= postgresDate && event.end_date >= postgresDate
                );

                if (isEvent) {
                    // cross light and dark purple
                    if(isEventStart && isEventEnd) {
                        if(isEventStart.start_date === isEventEnd.end_date ){
                            if (isEventEnd.event_color === 1) {
                                return "purple-light-purple-dark"
                            } else {
                                return "purple-dark-purple-light"
                            }
                        }
                    }
                    // cross with green
                    if(isEventStart?.start_date === postgresDate){
                        if (isEventStart.event_color === 1) {
                            return "green-purple-light"
                        } else {
                            return "green-purple-dark"
                        }
                    }
                    // cross with green
                    if(isEventEnd?.end_date === postgresDate){
                        if (isEventEnd.event_color === 1) {
                            return "purple-green-light"
                        } else {
                            return "purple-green-dark"
                        }
                    }
                    if (isEvent.event_color === 1) {
                        return "purple-light"
                    } else {
                        return "purple-dark"
                    }
                }
                if(calendarDate === undefined) {
                    return 'not-created-month'
                }
                return 'available-date'
            }

        };
    }, [cottageReservations, calendarDates]);

    useEffect(() => {
        const selectedStartDate = convertJsDateToPostgresFormat(date[0])
        const selectedEndDate = convertJsDateToPostgresFormat(date[1])

        if (selectedStartDate !== undefined) {
            setShowButton(true);
        }

        const isReserved = cottageReservations.some(reservation =>
            selectedStartDate < convertJsDateToPostgresFormat(reservation.UserReservation.end_date) && selectedEndDate > convertJsDateToPostgresFormat(reservation.UserReservation.start_date)
        );
        setRezervated(isReserved);

        // Calculate the count of selected dates
        const count = date instanceof Array ? date.length : 0;
        setSelectedDasCount(count);
    }, [date]);


    // GET event action information
    const [eventInformation, setEventInformation] = useState([])
    useEffect(() => {
        if (calendarDates.length > 0) {
            let monthEvents = calendarDates?.filter((date) => date?.eventId !== null &&
                new Date(date?.date).getMonth() + 1 === new Date(defaultMonthLabel?.date).getMonth() + 1 &&
                new Date(date?.date).getFullYear() === new Date(defaultMonthLabel?.date).getFullYear())

            const availableEventIds = availableEventDates.map(item => item.event_id)
            // Filter just not reserved EVENTS
            monthEvents = monthEvents.filter(item => availableEventIds.includes(item.eventId))

            // works as groupBy
            const eventGrouped = monthEvents.reduce((x, y) => {
                (x[y.eventId] = x[y.eventId] || []).push(y);
                return x;
            }, {});

            setEventInformation(eventGrouped)
        }
    }, [defaultMonthLabel])

    return (
        <div
            className="container front-page rezervation-page d-flex flex-column w-100 justify-content-center align-content-center"
            id="rezervation-page">
            <h1>Rezervace termínu</h1>
            <div className="calendar-container">
                {
                    loadingCalendar ? (
                        <>
                            Kalendář se načítá ...
                        </>
                    ) : (
                        <Calendar
                            onChange={handleDateChange}
                            value={date}
                            selectRange={true}
                            maxDate={lastAvailableDate} // will not allow date later than today
                            minDate={new Date()} // will not allow date before current Date
                            defaultView="month"
                            maxDetail="month"
                            showNavigation={true}
                            showNeighboringMonth={false}
                            tileClassName={tileClassName}
                        />
                    )
                }
            </div>
            <div className={"d-flex justify-content-center w-100 flex-column align-items-center"}>
                <AvailableColorBox defaultMonthLabel={defaultMonthLabel}/>
                <UnAvailableColorBox/>
                <EventColorBox eventsInformation={eventInformation}/>
            </div>
            <ConfirmationModal handleClose={handleClose} show={show}
                               modalTitle={"Potvrzení termínu"}
                               modalText={"Opravdu chcete vybrat tento termín ?"}
                               modalConfirmText={"Potvrdit"}
                               modalCloseText={"Zrušit"}
                               startDate={date[0]}
                               endDate={date[1]}
                               redirectUrl={`/reservation/cottage/start/${date[0]}/end/${date[1]}/product/${mainCottageId}/event/${eventId}`}
                               rezervated={rezervated}
            />
        </div>
    )
        ;
}

export default RezervationPage;
