import React from 'react';
import {Bars} from "react-loader-spinner";

function LoadingBar(props) {
    return (
        <div
            className="h-100 w-100 d-flex justify-content-center align-items-center position-absolute top-0 right-0 flex-column p-0 m-0" style={{position: "absolute", right:"0"}}>
            <Bars
                height="80"
                width="80"
                color="#4fa94d"
                ariaLabel="bars-loading"
                wrapperStyle={{}}
                wrapperClass=""
                visible={true}
            />
            Načítání...
        </div>
    );
}

export default LoadingBar;