import React, {useEffect, useState} from 'react';
import Navbar from "../../Navbar/Navbar";
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid' // a plugin!
import allLocales from '@fullcalendar/core/locales-all'
import interactionPlugin from "@fullcalendar/interaction" // needed for dayClick
import {useForm} from "react-hook-form"
import axios from "axios";
import UniversalModal from "../../../components/Modals/UniversalModal/UniversalModal";
import Swal from 'sweetalert2'
import LoadingBar from "../../../components/LoadingBar/LoadingBar";
import './CalendarSettings.css'

function CalendarSettings(props) {
    const currentDate = new Date()

    const [month, setMonth] = useState(String(currentDate.getMonth() + 1).padStart(2, '0'))
    const [year, setYear] = useState(currentDate.getFullYear())
    const [isOpen, setIsOpen] = useState(false)
    const [startDate, setStartDate] = useState()
    const [endDate, setEndDate] = useState()

    const [cottageMainId, setCottageMainId] = useState()
    const [isLoading, setIsLoading] = useState(false)

    const [daysInfo, setDaysInfo] = useState([])

    const {
        register,
        handleSubmit,
        setValue,
        getValues,
    } = useForm()

    const handleDatesSet = (arg) => {
        setMonth((arg.view.currentStart).getMonth() + 1)
        setYear((arg.view.currentStart).getFullYear())
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                // Get Cottage Main Id
                const response = await axios.get(`${process.env.REACT_APP_SERVER_URL}/api/cottage/main-cottage`);
                setCottageMainId(response.data.mainCottage[0].cottage_id);
            } catch (error) {
                alert(error.message);
            }
        };
        fetchData(); // Invoke the async function directly
        // Cleanup function (not needed in this case, as there are no subscriptions or intervals)
        return () => {
            // Cleanup code (if any)
        };
    }, []);

    const fetchCalendarDatesData = async () => {
        setDaysInfo([])
        try {
            const response = await axios.get(`${process.env.REACT_APP_SERVER_URL}/api/admin/cottage-calendar/cottage/${cottageMainId}/year/${year}/month/${month}/calendar-dates`, {
                headers: {
                    "authorization": `Bearer ${localStorage.getItem('token')}`
                }
            })
            setValue("price_per_night", parseInt(response.data.data[0].MonthDateInfoPrice.price_per_night))
            setValue("min_length_count", parseInt(response.data.data[0].MonthDateInfoPrice.min_days_rent_length))
            setValue("label_description", (response.data.data[0].MonthDateInfoPrice.Label.label_name))
            response.data.data.map((day) => {
                setDaysInfo((prev) => [
                        ...prev,
                        {
                            day: day.day_number,
                            month: day.MonthDateInfoPrice.Month.month_number,
                            eventId: day.Event?.event_id,
                            eventPrice: day.Event?.price_per_night,
                            title: day.Event?.Label.label_name ? day.Event.Label.label_name : day.MonthDateInfoPrice.Label.label_name,
                            date: day.MonthDateInfoPrice.year_number + "-" + String(day.MonthDateInfoPrice.Month.month_number).padStart(2, '0') + "-" + String(day.day_number).padStart(2, '0'),
                            color: day.Event?.Label.label_name ? "purple" : "green"
                        }
                    ]
                )
            })

            console.log(response.data.data, ' response.data.data')
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        if (cottageMainId) {
            fetchCalendarDatesData()
            localStorage.setItem('year', year);
            localStorage.setItem('month', String(month).padStart(2, '0'))
            setValue("price_per_night", "")
            setValue("min_length_count", "")
            setValue("label_description", "")
        }
    }, [cottageMainId, month, year])

    // Create Default Month Setting
    const onSubmit = async (data) => {
        try {
            setIsLoading(true)
            let response = null;
            // Update Month Default Info
            if (daysInfo.length !== 0) {
                response = await axios.put(`${process.env.REACT_APP_SERVER_URL}/api/admin/cottage-calendar/cottage/${cottageMainId}/year/${year}/month/${month}/default-month-setting`, {
                    pricePerNight: getValues("price_per_night"),
                    minLengthCount: getValues("min_length_count"),
                    labelDescription: getValues("label_description")
                },{
                    headers:{
                        "authorization" : `Bearer ${localStorage.getItem('token')}`
                    }
                })
                // It is not necessary to update all titles, just the one that has been changed
                const updatedTitles = daysInfo.map((dayInfo) => {
                    if (dayInfo.eventId == null) {
                        return {...dayInfo, title: getValues("label_description")}
                    }
                    return dayInfo
                })
                setDaysInfo(updatedTitles)
            } else {
                response = await axios.post(`${process.env.REACT_APP_SERVER_URL}/api/admin/cottage-calendar/cottage/${cottageMainId}/year/${year}/month/${month}/default-month-setting`, {
                    pricePerNight: data.price_per_night,
                    minLengthCount: data.min_length_count,
                    labelDescription: data.label_description
                }, {
                    headers:{
                        "authorization" : `Bearer ${localStorage.getItem('token')}`
                    }
                })
                fetchCalendarDatesData(); // I need fetch data again to get titles for every day
            }
            console.log(response)
            await Swal.fire({
                title: 'Default Nastavení ',
                text: `Default nastavení měsíce bylo úspěšně uloženo`,
                icon: 'success'
            })
        } catch (error) {
            console.log(error.message)
            await Swal.fire(
                'Nastala chyba!',
                `${error.response.data.message}`,
                'error'
            )
        } finally {
            setIsLoading(false)
        }
    }

    // Create Event for current Month
    const handleCreateEvent = async (formData) => {
        console.log(formData, ' formData');
        setIsOpen(false) // Close Modal
        setIsLoading(true)
        try {
            const createEvent = await axios({
                method: 'put',
                url: `${process.env.REACT_APP_SERVER_URL}/api/admin/cottage-calendar/cottage/${cottageMainId}/create-event`,
                data: {
                    pricePerNight: formData.price_per_night,
                    startDate: formData.start_date,
                    endDate: formData.end_date,
                    labelDescription: formData.event_label_description
                },
                headers: {
                    "authorization": `Bearer ${localStorage.getItem('token')}`
                }
            })
            fetchCalendarDatesData() // I need to fetch data again to get new event id
            await Swal.fire({
                title: 'Akce byla vytvořena',
                text: createEvent.data.message,
                icon: "success",
            })
        } catch (error) {
            await Swal.fire({
                title: 'Akce Nebyla vytvořena ',
                text: error.response.data.message || 'Nastala chyba',
                icon: 'error'
            })
        } finally {
            setIsLoading(false)
        }
    }

    const [eventToUpdate, setEventToUpdate] = useState({})
    const handleEventClick = async (eventId) => {
        console.log(eventId, ' event id');
        if (eventId) { // Display already created event
            setIsOpen(true)
            const res = daysInfo.filter((dayInfo) => dayInfo.eventId === eventId)
            console.log(res)
            setStartDate(res[0].date)
            setEndDate(res[res.length - 1].date)
            setEventToUpdate({
                eventId: eventId,
                eventPrice: res[0].eventPrice,
                labelDescription: res[0].title,
            })
        } else { // Open modal to create new event
            setIsOpen(true)
            setEventToUpdate(false)
        }
    }

    // Delete Event
    const handleDeleteEvent = async (eventId) => {
        try {
            setIsOpen(false)
            setIsLoading(true)
            const response = await axios.delete(`${process.env.REACT_APP_SERVER_URL}/api/admin/cottage-calendar/event/${eventId}/delete-event`, {
                headers: {
                    "authorization": `Bearer ${localStorage.getItem('token')}`
                }
            })
            await Swal.fire({
                title: 'Akce byla smazána',
                text: response.data.message,
                icon: "success",
            })
            const deletedEvent = daysInfo.map((dayInfo) => {
                if (dayInfo.eventId === eventId) {
                    return {...dayInfo, eventId: null, title: getValues("label_description"), color: 'green'}
                }
                return dayInfo
            })
            setDaysInfo(deletedEvent)
        } catch (error) {
            await Swal.fire({
                title: 'Akce Nebyla smazána ',
                text: error.response.data.message || 'Nastala chyba',
                icon: 'error'
            })
        } finally {
            setIsLoading(false)
        }
    }

    return (
        <>
            <Navbar/>
            <div className="container front-page admin-calendar-settings">
                {
                    isLoading ? (
                        <LoadingBar/>
                    ) : (
                        <>
                            <h2>Nastavení kalendáře</h2>
                            <FullCalendar
                                plugins={[dayGridPlugin, interactionPlugin]}
                                locales={allLocales}
                                locale={'cs'}
                                initialView="dayGridMonth"
                                weekends={true}
                                lazyFetching={true}
                                selectable={true}
                                events={daysInfo}
                                select={(info) => {
                                    setStartDate(info.startStr)
                                    setEndDate(info.end.toISOString().slice(0, 10))

                                    // Check if event already exists in this selected date
                                    // const eventId = daysInfo.find((dayInfo) => dayInfo.date === info.startStr && (dayInfo.eventId))?.eventId
                                    const eventId = false
                                    eventId ? handleEventClick(eventId) : setEventToUpdate(false)
                                    setIsOpen(true)
                                }}
                                eventContent={(arg) => {
                                    const element = document.createElement('div');
                                    element.innerHTML = arg.event.title;

                                    element.style.background = arg.event._def.extendedProps.color
                                    return {domNodes: [element]};
                                }}
                                displayEventTime={false} // disable indicating 12a
                                datesSet={handleDatesSet}
                                initialDate={`${localStorage.getItem('year') ?? currentDate.getFullYear()}-${localStorage.getItem('month') ?? String(currentDate.getMonth() + 1).padStart(2, '0')}-01`}
                                eventClick={(e) => handleEventClick(e.event._def.extendedProps.eventId)}
                            />

                            <UniversalModal
                                title={eventToUpdate ? "Zde můžete smazat Akci" : "Zde můžete vytvořit Akci"}
                                text="Zde můžete nastavit či smazat Akci"
                                isOpen={isOpen}
                                handleClose={() => setIsOpen(false)}
                                modalFormData={eventToUpdate ? handleDeleteEvent : handleCreateEvent}   // It will chose DELETE or CREATE
                                action={eventToUpdate ? "delete" : "create"}                           // It will chose DELETE or CREATE
                                unique_id={eventToUpdate.eventId}
                                inputs={[
                                    {
                                        label: "Začátek (00:00:00)",
                                        type: "date",
                                        name: "start_date",
                                        readOnly: true,
                                        required: false,
                                        value: startDate
                                    },
                                    {
                                        label: "Konec (23:59:59)",
                                        type: "date",
                                        name: "end_date",
                                        readOnly: true,
                                        required: false,
                                        value: endDate
                                    },
                                    {
                                        label: "Cena za Akci",
                                        type: "number",
                                        name: "price_per_night",
                                        placeholder: "Cena za Akci",
                                        required: true,
                                        value: eventToUpdate.eventPrice ?? null
                                    },
                                    {
                                        label: "Popisek",
                                        type: "text",
                                        name: "event_label_description",
                                        placeholder: "Popisek",
                                        required: true,
                                        value: eventToUpdate.labelDescription ?? null
                                    }
                                ]}
                            />

                            <form onSubmit={handleSubmit(onSubmit)} className="p-5">
                                <div className="form-group">
                                    <label>Výchozí cena za 1 noc</label>
                                    <input type="number" className="form-control"
                                           placeholder="Cena ..." {...register("price_per_night")}
                                           onChange={(e) => setValue("price_per_night", e.target.value)}/>
                                </div>
                                <div className="form-group">
                                    <label>Minimální počet nocí </label>
                                    <input type="number" className="form-control"
                                           placeholder="Minimální počet nocí" {...register("min_length_count")}
                                    />
                                </div>
                                <div className="form-group">
                                    <label>Popisek </label>
                                    <input type="text" className="form-control"
                                           placeholder="Popisek " {...register("label_description")}
                                    />
                                </div>
                                <div className="form-group">
                                    <input type="submit" className="form-control rezervation-button w-100"
                                           value="Odeslat "/>
                                </div>
                            </form>
                        </>
                    )
                }
            </div>
        </>
    );
}

export default CalendarSettings;
