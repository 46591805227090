import React from 'react';
import { Outlet, Navigate } from 'react-router-dom';
import {useTokenVerification} from "../utils/jwtTokenHelper";

function PrivateRoutes() {
    // const [isValidToken, setIsValidToken] = useState(null);
    //
    // useEffect(() => {
    //     const verifyJwtToken = async () => {
    //         const token = localStorage.getItem('token');
    //
    //         if (token) {
    //             try {
    //                 const response = await axios.post(`${process.env.REACT_APP_SERVER_URL}/api/users/verify-token`, { token });
    //                 console.log(response.data.verified);
    //
    //                 if (response.data.verified) {
    //                     setIsValidToken(true);
    //                 } else {
    //                     setIsValidToken(false);
    //                 }
    //             } catch (error) {
    //                 console.error('Error verifying token:', error);
    //                 setIsValidToken(false);
    //             }
    //         } else {
    //             setIsValidToken(false);
    //         }
    //     };
    //
    //     verifyJwtToken();
    // }, []); // empty dependency array, so it runs once after the initial render
    //
    // console.log(isValidToken)

    const isValidToken = useTokenVerification();
    if(isValidToken !== null) {
        return isValidToken ? <Outlet /> : <Navigate to="/" />;
    }
}

export default PrivateRoutes;
