import React, {useEffect, useState} from 'react';
import "./MainPage.css"
import {Link} from "react-scroll";

function MainPage() {
    return (
        <div className="main-page" id="main-page" alt={"Pronájem chalupy v Komorní Lhotce. Ideální místo pro vaši dovolenou. Rezervujte ještě dnes!"} >
            <div className="container p-4 text-light" alt={"Pronájem chalupy v Komorní Lhotce. Ideální místo pro vaši dovolenou. Rezervujte ještě dnes!"}>
                <div className="rezervation-part" alt={"Pronájem chalupy v Komorní Lhotce. Ideální místo pro vaši dovolenou. Rezervujte ještě dnes!"}>
                    <h1 className="font-weight-bold">Roubenka Maruška Komorní Lhotka</h1>
                    <Link to="rezervation-page" spy={true} smooth={true} offset={0} duration={500} alt={"Pronájem chalupy v Komorní Lhotce. Ideální místo pro vaši dovolenou. Rezervujte ještě dnes!"}>
                        <button className="rezervation-button">Rezervovat zde</button>
                    </Link>
                </div>
            </div>
        </div>
    );
}

export default MainPage;
