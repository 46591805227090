import React from 'react';
import {formatItemsDescription} from "../utils/helper";

function ReadMoreLessFormatted({item, lightDot, lessLenght}) {

    // je treba udelat univerzalnejsi reseni, aby se to dalo pouzit i na jine stranky
    const [readLessMore, setReadLessMore] = React.useState([]);
    React.useEffect(() => {
        const newReadLessMore = [];
        for (let i = 0; i < 30; i++) {
            newReadLessMore.push(false);
        }
        setReadLessMore(newReadLessMore);
    }, []);

    const handleReadMore = (index) => {
        const newReadLessMore = [...readLessMore];
        newReadLessMore[index] = !newReadLessMore[index];
        setReadLessMore(newReadLessMore);
    };

    const {description, item_id: itemId} = item;

    return (<div>
        <div onClick={() => handleReadMore(itemId)}
             className={"w-100 pb-2"}
             style={{fontSize: "16px", cursor: "pointer", fontWeight: "500"}}>
            {!readLessMore[itemId] &&
                <div style={{flex: "flex", flexDirection: "column"}}>
                    {<div>
                            <span
                                className={"text-capitalize"}
                                dangerouslySetInnerHTML={{__html: formatItemsDescription(description, '+').toString().substring(0, lessLenght)}}/>
                        ... <strong>Více informací</strong>
                    </div>

                    }
                </div>}
        </div>
        <div className={"text-left mb-2"}>
            {readLessMore[itemId] ? (<>
                {formatItemsDescription(description, '+').map((item, index) => (
                    <div className={"text-left"} key={index}>
                    <span dangerouslySetInnerHTML={{__html: item}}
                          style={{textTransform: "capitalize"}}
                          className={`${lightDot ? 'line-light' : 'line'}`}/>
                        <br></br>
                    </div>
                ))}
                <strong onClick={() => {
                    handleReadMore(itemId)
                }} style={{cursor: "pointer"}}>Méně informací</strong>
            </>) : (<span></span>)}
        </div>
    </div>);
}

export default ReadMoreLessFormatted;
