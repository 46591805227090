import React, {useState} from 'react';
import axios from "axios";
import {useForm} from 'react-hook-form'
import "./LoginPage.css"
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";

function LoginPage(props) {
    const {register, handleSubmit, formState: {errors}} = useForm();
    const navigate = useNavigate();

    const onSubmit = async (data) => {
        try {
            // Make an HTTP POST request to send the form data to the server
            const response = await axios.post(`${process.env.REACT_APP_SERVER_URL}/api/users/login`, data);
            localStorage.setItem("token",response.data.data);
            console.log(response.data)
            Swal.fire(
                'Úspěšně přihlášeno ',
                `${response.data.message}`,
                'success'
            )
            navigate("/admin/reservation/overview");
            console.log('meassage:', response.data.message);
        } catch (error) {
            if (error.response) {
                Swal.fire(
                    'Nastala chyba!',
                    `${error.response.data.message}`,
                    'error'
                )
            }
        }
    };


    return (
        <div className="container-login">
            <div className="wrapper">
                <div className="title"><span>Admin Login Form</span></div>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="row">
                        <i className="fas fa-user"></i>
                        <input {...register("email", {required: "Povinné pole"})} className="form-control" type="text" placeholder="Email..."/>
                    </div>
                    <div className="row">
                        <i className="fas fa-lock"></i>
                        <input {...register("password", {required: "Povinné pole"})} className="form-control" type="password" placeholder="Heslo..."/>
                    </div>
                    <div className="row button">
                        <input type="submit" value="Login" />
                    </div>
                </form>
            </div>
        </div>
    );
}

export default LoginPage;