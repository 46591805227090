import React from 'react';
import {Navigate} from "react-router-dom";

function Logout(props) {
    localStorage.clear();

    return (
        <div>
            <Navigate to={"/admin/login"}/>
        </div>
    );
}

export default Logout;