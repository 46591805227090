import React, {useEffect, useState} from 'react';
import Navbar from "../Navbar/Navbar";
import axios from "axios";
import './ItemsInfoPage.css'
import LoadingBar from "../../components/LoadingBar/LoadingBar";
import PictureOpenModal from "../../components/Modals/PictureOpenModal";
import ReadMoreLessFormatted from "../../components/ReadMoreLessFormatted";

function ItemsInfoPage() {
    const [items, setItems] = useState([]);
    const [loading, setLoading] = useState(false);

    const getAllCottageItems = async (cottageId) => {
        try {
            // setIsLoading(true);
            const cottageItems = await axios.get(`${process.env.REACT_APP_SERVER_URL}/api/items/cottage/${cottageId}/all`);
            setItems(cottageItems.data.allCottageItems);
        } catch (error) {
            console.error('Error while fetching data:', error);
        } finally {
            setLoading(false);
        }
    };

    const getMainCottageId = async () => {
        let mainCottageIdFromDB = 0
        setLoading(true)
        try {
            let mainCottageFromDB = await axios.get(`${process.env.REACT_APP_SERVER_URL}/api/cottage/main-cottage`)
            mainCottageIdFromDB = mainCottageFromDB.data.mainCottage[0].cottage_id
        } catch (error) {
            console.error('Error while fetching data:', error);
        } finally {
            getAllCottageItems(mainCottageIdFromDB)
        }
    }


    useEffect(() => {
        getMainCottageId()
    }, []);

    const [imageCategoryId, setImageCategoryId] = useState(null);

    const handleSetImageNullClick = () => {
        setImageCategoryId(null)
    }


    return (
        <>
            <Navbar/>
            {
                loading && <LoadingBar/>
            }
            <div className="container front-page information-page" id="information-page" alt={"Chalupa Komorni Lhotka"}>
                <div className="introduction" alt={"Chalupa Komorni Lhotka"}>
                    <h1>Informace o elektrokolech a čtyřkolce</h1>

                    <div className={"items-list"}>
                        {
                            items.map((item, index) => (
                                index % 2 === 0 ? (
                                    <div className={"item p-3 d-flex flex-lg-row flex-column bg-light align-items-center"} key={index}>
                                        <img
                                            src={`/images/${item.Item.image_url}`} alt={item.Item.name}
                                            className={"img-item-info"} onClick={() => {
                                            setImageCategoryId(item.Item.item_id)
                                        }}/>
                                        <div className={"p-3 w-100 text-left"}>
                                            <h2>{item.Item.name}</h2>
                                            <ReadMoreLessFormatted item={item.Item} lessLenght={100}/>
                                        </div>
                                    </div>
                                ) : (
                                    <div
                                        className={"item p-3 d-flex flex-lg-row-reverse flex-column bg-dark text-light justify-content-between align-items-center"}
                                        key={index}>
                                        <img
                                            src={`/images/${item.Item.image_url}`} alt={item.Item.name}
                                            className={"img-item-info"} onClick={() => {
                                            setImageCategoryId(item.Item.item_id)
                                        }}/>
                                        <div className={"p-3 w-100 text-left"}>
                                            <h2>{item.Item.name}</h2>
                                            <ReadMoreLessFormatted lightDot item={item.Item} lessLenght={100}/>
                                        </div>
                                    </div>
                                )
                            ))
                        }
                    </div>
                </div>
            </div>


            <PictureOpenModal clickedImageCategoryId={imageCategoryId}
                              setClickedImageCategoryId={handleSetImageNullClick}/>
        </>
    );
}

export default ItemsInfoPage;
