const dataItems = [{
    id: 1,
    image: "/images/bikes/Crussis E-Atland - 01 Medium.jpeg",
    categoryId: 1,
}, {
    id: 2,
    image: "/images/bikes/Crussis E-Atland - 02 Medium.jpeg",
    categoryId: 1,
}, {
    id: 3,
    image: "/images/bikes/Crussis E-Atland - 03 Medium.jpeg",
    categoryId: 1,
}, {
    id: 4,
    image: "/images/bikes/Crussis E-Atland - 04 Medium.jpeg",
    categoryId: 1,
}, {
    id: 5,
    image: "/images/bikes/Crussis E-Atland - 05 Medium.jpeg",
    categoryId: 1,
}, {
    id: 6,
    image: "/images/bikes/Crussis E-Atland - 06 Medium.jpeg",
    categoryId: 1,
}, {
    id: 7,
    image: "/images/bikes/Crussis E-Atland - 07 Medium.jpeg",
    categoryId: 1,
}, {
    id: 8,
    image: "/images/bikes/Crussis E-Atland - 08 Medium.jpeg",
    categoryId: 1,
}, {
    id: 9,
    image: "/images/bikes/Crussis Oli Guera 8.7 - 01 Medium.jpeg",
    categoryId: 4,
}, {
    id: 10,
    image: "/images/bikes/Crussis Oli Guera 8.7 - 02 Medium.jpeg",
    categoryId: 4,
}, {
    id: 11,
    image: "/images/bikes/Crussis Oli Guera 8.7 - 03 Medium.jpeg",
    categoryId: 4,
}, {
    id: 12,
    image: "/images/bikes/Crussis Oli Guera 8.7 - 04 Medium.jpeg",
    categoryId: 4,
}, {
    id: 13,
    image: "/images/bikes/Crussis Oli Guera 8.7 - 05 Medium.jpeg",
    categoryId: 4,
}, {
    id: 14,
    image: "/images/bikes/Crussis Oli Guera 8.7 - 06 Medium.jpeg",
    categoryId: 4,
}, {
    id: 15,
    image: "/images/bikes/Crussis Oli Guera 8.7 - 07 Medium.jpeg",
    categoryId: 4,
}, {
    id: 16,
    image: "/images/bikes/Crussis Oli Guera 8.7 - 08 Medium.jpeg",
    categoryId: 4,
}, {
    id: 17,
    image: "/images/bikes/Crussis One Guera 7.8 - 01 Medium.jpeg",
    categoryId: 5,
}, {
    id: 18,
    image: "/images/bikes/Crussis One Guera 7.8 - 02 Medium.jpeg",
    categoryId: 5,
}, {
    id: 19,
    image: "/images/bikes/Crussis One Guera 7.8 - 03 Medium.jpeg",
    categoryId: 5,
}, {
    id: 20,
    image: "/images/bikes/Crussis One Guera 7.8 - 04 Medium.jpeg",
    categoryId: 5,
}, {
    id: 21,
    image: "/images/bikes/Crussis One Guera 7.8 - 05 Medium.jpeg",
    categoryId: 5,
}, {
    id: 22,
    image: "/images/bikes/Crussis One Guera 7.8 - 06 Medium.jpeg",
    categoryId: 5,
},
{
    id: 23,
    image: "/images/bikes/Crussis One Guera 7.8 - 07 Medium.jpeg",
    categoryId: 5,
}, {
    id: 24,
    image: "/images/bikes/Crussis One Guera 7.8 - 08 Medium.jpeg",
        categoryId: 5,
}, {
    id: 25,
    image: "/images/bikes/Crussis One Guera 7.8 - 09 Medium.jpeg",
    categoryId: 5,
},
, {
    id: 26,
    image: "/images/bikes/Haibike HardNine 7 - 01 Medium.jpeg",
        categoryId: 2,
}, {
    id: 27,
        image: "/images/bikes/Haibike HardNine 7 - 02 Medium.jpeg",
        categoryId: 2,
}, {
    id: 28,
        image: "/images/bikes/Haibike HardNine 7 - 03 Medium.jpeg",
        categoryId: 2,
}, {
    id: 29,
        image: "/images/bikes/Haibike HardNine 7 - 04 Medium.jpeg",
    categoryId: 2,
}, {
    id: 30,
        image: "/images/bikes/Haibike HardNine 7 - 05 Medium.jpeg",
        categoryId: 2,
}, {
    id: 31,
        image: "/images/bikes/Haibike HardNine 7 - 06 Medium.jpeg",
        categoryId: 2,
}, {
    id: 32,
        image: "/images/bikes/Haibike HardNine 7 - 07 Medium.jpeg",
        categoryId: 2,
}, {
    id: 33,
        image: "/images/bikes/Haibike HardNine 7 - 08 Medium.jpeg",
        categoryId: 2,
}, {
    id: 34,
        image: "/images/bikes/Haibike HardNine 7 - 09 Medium.jpeg",
        categoryId: 2,
}, {
    id: 35,
        image: "/images/bikes/Haibike HardNine 7 - 10 Medium.jpeg",
    categoryId: 2,
}, {
    id: 2360,
        image: "/images/bikes/Haibike HardNine 7 - 11 Medium.jpeg",
    categoryId: 2,
},
]
export default dataItems;
