
import './App.css';
import {BrowserRouter, Routes, Route, HashRouter} from "react-router-dom";
import AllSectionsPage from "./pages/AllSectionsPage/AllSectionsPage";
import LoginPage from "./pages/Admin/LoginPage/LoginPage";
import ReservationsOverView from "./pages/Admin/ReservationsOverView/ReservationsOverView";

import React from "react";
import PrivateRoutes from "./utils/PrivateRoutes";
import Logout from "./pages/Admin/Logout/Logout";
import CalendarSettings from "./pages/Admin/CalendarSettings/CalendarSettings";
import CustomerRezervationForCottagePage from "./pages/CustomerRezervationPage/CustomerRezervationForCottagePage";
import axios from "axios";
import ItemsInfoPage from "./pages/ItemsInfoPage/ItemsInfoPage";
import {addYears} from "./utils/helper";




function App() {
    return (
        <div className="App">
            <BrowserRouter>
                <Routes>
                    <Route path="/" element={<AllSectionsPage />}/>
                    <Route path="/items-info-page" element={<ItemsInfoPage />} />
                    <Route path="/cottage/:cottageId" element={<AllSectionsPage />}/>
                    <Route path="/reservation/cottage/start/:startDate/end/:endDate/product/:productId/event/:eventId" element={<CustomerRezervationForCottagePage />} />
                    <Route path="/admin/login" element={<LoginPage />} />

                    {/*Protected Routes*/}
                    <Route element={<PrivateRoutes />}>
                        <Route path="/admin/reservation/overview" element={<ReservationsOverView />} />
                        <Route path="/admin/logout" element={<Logout />} />
                        <Route path="/admin/calendar-settings" element={<CalendarSettings />} />
                    </Route>

                </Routes>
            </BrowserRouter>
        </div>

    );
}

export default App;
