const formattedDate = (dateString) => {
    const date = new Date(dateString);
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Month is 0-based
    const year = date.getFullYear();
    return `${day}.${month}.${year}`;
}

const formattedDateInput = (dateString) => {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');

    return `${year}-${month}-${day}`;
    // return `${year}-${month}-${day}T10:00`;
};
// utils/helper.js
const formattedDateTimeLocal = (dateString) => {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');
    const hours = date.getHours().toString().padStart(2, '0');
    const minutes = date.getMinutes().toString().padStart(2, '0');

    return `${year}-${month}-${day}T${hours}:${minutes}`;
    // return `${year}-${month}-${day}T10:00`;
};
const formattedDateTimeLocalDisplay = (dateString) => {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');
    const hours = date.getHours().toString().padStart(2, '0');
    const minutes = date.getMinutes().toString().padStart(2, '0');

    return `${day}.${month}.${year}/${hours}:${minutes}`;
    // return `${year}-${month}-${day}T10:00`;
};

const convertJsDateToPostgresFormat = (jsDate) => {
    jsDate = new Date(jsDate);
    const year = jsDate.getFullYear();
    const month = (jsDate.getMonth() + 1).toString().padStart(2, '0');
    const day = jsDate.getDate().toString().padStart(2, '0');

    return `${year}-${month}-${day}`;
}

const displayMonthsOfPricing = (from, till) => {
    const arr = [];
    if (from > till) {
        for (let i = from; i <= 12; i++) {
            arr.push(i);
        }
        for (let i = 1; i <= till; i++) {
            arr.push(i);
        }
    } else {
        for (let i = from; i <= till; i++) {
            arr.push(i);
        }
    }
    console.log(arr);
    return arr;
};

const displayPriceForReservationMonth = (from, till, startReservationMonth) => {
    const arr = displayMonthsOfPricing(from, till);

    return arr.includes(startReservationMonth) ? true : false;
};

let CZKCurrency = new Intl.NumberFormat('cs-EU', {
    style: 'currency',
    currency: 'CZK',
});

function addYears(date, years) {
    date.setFullYear(date.getFullYear() + years);
    return date;
}

const mostFrequent = (arr, mapFn = x => x) =>
    Object.entries(
        arr.reduce((a, v) => {
            const k = mapFn(v);
            a[k] = (a[k] ?? 0) + 1;
            return a;
        }, {})
    ).reduce((a, v) => (v[1] >= a[1] ? v : a), [null, 0])[0];


const formatItemsDescription = (itemDescription, char ) => {
    if(itemDescription === null || itemDescription === undefined) return [];
    return itemDescription.trim().split(char);
}

module.exports = {
    displayMonthsOfPricing,
    displayPriceForReservationMonth,
    convertJsDateToPostgresFormat,
    formattedDate,
    formattedDateTimeLocal,
    formattedDateTimeLocalDisplay,
    CZKCurrency,
    addYears,
    formattedDateInput,
    mostFrequent,
    formatItemsDescription
};


