import React, {useEffect, useState} from 'react';
import './CottageItem.css';
import axios from 'axios';
import Modal from 'react-bootstrap/Modal';
import {formattedDateInput} from '../../utils/helper';
import PictureOpenModal from "../Modals/PictureOpenModal";
import ReactReadMoreReadLess from "react-read-more-read-less";
import CottageItemBox from "./CottageItemBox";

function CottageItem({cottageId, startDate, endDate, onDataReceived, itemsReservations}) {
    const [items, setItems] = useState([]);
    const [isDivVisible, setIsDivVisible] = useState(true);
    const [selectedItem, setSelectedItem] = useState([]);
    const [startDateItem, setStartDateItem] = useState(formattedDateInput(startDate));
    const [endDateItem, setEndDateItem] = useState(formattedDateInput(endDate));
    const [amountItem, setAmountItem] = useState(1);
    const [show, setShow] = useState(false);
    const [showAlert, setShowAlert] = useState({show: false, message: ""});

    useEffect(() => {
        setStartDateItem(formattedDateInput(startDate));
        setEndDateItem(formattedDateInput(endDate));
    }, [show])

    const toggleVisibility = () => {
        setIsDivVisible(!isDivVisible);
    };

    const itemRentLength = (end, start) => {
        return (new Date(end) - new Date(start)) / (3600000 * 24)
    }


    const getAllCottageItems = async () => {
        try {
            // setIsLoading(true);
            const cottageItems = await axios.get(`${process.env.REACT_APP_SERVER_URL}/api/items/cottage/${cottageId}/all`);
            setItems(cottageItems.data.allCottageItems);
        } catch (error) {
            console.error('Error while fetching data:', error);
        } finally {
            // setIsLoading(false);
        }
    };

    useEffect(() => {
        getAllCottageItems();
    }, []);

    const openModal = (item) => {
        setShow(true);
        setSelectedItem(item);
    };

    const sendDataToParent = (data) => {
        onDataReceived(data);
        setShow(false);
        setAmountItem(1);
    };

    const validateDates = (start, end) => {
        const selectedStartDate = formattedDateInput(new Date(start));
        const selectedEndDate = formattedDateInput(new Date(end));
        const allowedStartDate = formattedDateInput(new Date(startDate));
        const allowedEndDate = formattedDateInput(new Date(endDate));

        if (itemRentLength(end, start) === 0) {
            return false
        }
        return ((selectedStartDate >= allowedStartDate && selectedEndDate <= allowedEndDate) && end >= start)
    };

    const saveReservation = (item, stDate, enDate, amount) => {
        if (validateDates(stDate, enDate)) {
            sendDataToParent({
                item_id: item.item_id,
                item_name: item.Item.name,
                item_start_date: stDate,
                item_end_date: enDate,
                amount: amount,
            });
        } else {
            setShowAlert({
                show: true,
                message: `Vaše datum musí být v rozsahu vašeho pobytu. Počet rezervovaných nocí musí být minimálně 1`
            })
        }
    };

    const deleteItem = (deleteItem) => {
        sendDataToParent({
            item_id: deleteItem.item_id,
            delete: true,
        });
    };

    const [imageCategoryId, setImageCategoryId] = useState(null)

    const handleSetImageNullClick = () => {
        setImageCategoryId(null)
    }

    return (
        <>
            <div>
                {isDivVisible ? (
                    <button className="btn btn-danger" onClick={toggleVisibility} type="button">
                        Schovat
                    </button>
                ) : (
                    <button className="btn btn-success" onClick={toggleVisibility} type="button">
                        Ano mám zájem
                    </button>
                )}
                <div className="items-box">
                    {isDivVisible &&
                        items.map((item) => {
                            const isItemReserved = itemsReservations.find((reservedItem) => reservedItem?.item_id === item?.item_id);

                            return (
                                <CottageItemBox
                                    key={item.Item.item_id}
                                    isItemReserved={isItemReserved}
                                    item={item}
                                    itemsSize={items.length}
                                    deleteItem={deleteItem}
                                    openModal={openModal}
                                    setImageCategoryId={setImageCategoryId}/>
                            );
                        })}
                </div>

                <Modal show={show}>
                    <Modal.Header style={{textAlign: "center"}}>
                        <Modal.Title>Věc k pronájmu </Modal.Title>
                    </Modal.Header>
                    <Modal.Body className="d-flex flex-column align-items-center">
                        {
                            showAlert.show && (
                                <div className="alert alert-danger alert-dismissible fade show" role="alert">
                                    {showAlert.message}
                                    <button type="button" className="close" onClick={() => {
                                        setShowAlert(!showAlert.show)
                                    }}>
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                            )
                        }
                        <>
                            {isDivVisible && selectedItem?.Item?.name}
                            <div className="w-75">
                                <label>Od</label>
                                <input
                                    type="date"
                                    className="form-control"
                                    value={startDateItem}
                                    onChange={(e) => setStartDateItem((e.target.value))}
                                />
                            </div>
                            <div className="w-75">
                                <label>Do</label>
                                <input
                                    type="date"
                                    className="form-control"
                                    value={endDateItem}
                                    onChange={(e) => setEndDateItem((e.target.value))}
                                />
                            </div>
                            <div className="w-75">
                                <label>Počet kusů</label>
                                <select
                                    className="form-control"
                                    value={amountItem}
                                    onChange={(e) => setAmountItem(e.target.value)}
                                    required
                                >
                                    {[...Array(selectedItem?.amount || 0).keys()].map((index) => (
                                        <option key={index + 1} value={index + 1}>
                                            {index + 1}
                                        </option>
                                    ))}
                                </select>
                            </div>
                            <div className='w-75'>
                                <p>Počet rezervovaných nocí</p>
                                <div className="form-control my-1" style={{background: "lightgray"}}>
                                    {(itemRentLength(endDateItem, startDateItem) || 0)}
                                </div>
                            </div>
                        </>
                    </Modal.Body>
                    <Modal.Footer>
                        <button className="btn btn-success"
                                onClick={() => saveReservation(selectedItem, startDateItem, endDateItem, amountItem)}>
                            Rezervovat
                        </button>
                        <button className="btn btn-danger" onClick={() => setShow(false)}>
                            Zrušit
                        </button>
                    </Modal.Footer>
                </Modal>
            </div>


            <PictureOpenModal clickedImageCategoryId={imageCategoryId} setClickedImageCategoryId={handleSetImageNullClick} />
        </>
    );
}

export default CottageItem;
