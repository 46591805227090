import React, {useEffect, lazy} from 'react';
import Navbar from "../Navbar/Navbar";
import MainPage from "../MainPage/MainPage";
import InformationPage from "../InformationPage/InformationPage";
import GalleryPage from "../GalleryPage/GalleryPage";
import FooterPage from "../FooterPage/FooterPage";
import MessageForm from "../MessageForm/MessageForm";
import InterestingFactsPage from "../InterestingFactsPage/InterestingFactsPage";
import PricingPage from "../PricingPage/PricingPage";

import RezervationPage from "../RezervationPage/RezervationPage";

function AllSectionsPage(props) {
    useEffect( () => {
        window.scrollTo(0, 0) // Scroll to top on mount
    }, []);

    return (
        <div>
            <Navbar />
            <MainPage  />
            <InformationPage />
            <InterestingFactsPage />
            <RezervationPage />
            <PricingPage />
            <GalleryPage />
            <MessageForm />
            <FooterPage />
        </div>
    );
}

export default AllSectionsPage;
