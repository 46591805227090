import React, {useEffect, useState} from 'react';
import {Link, useParams} from "react-router-dom";
import {useForm} from 'react-hook-form'
import {formattedDate, CZKCurrency} from "../../utils/helper";
import axios from "axios";
import Swal from 'sweetalert2'
import CottageItem from "../../components/CottageItem/CottageItem";
import LoadingBar from "../../components/LoadingBar/LoadingBar";
import Navbar from "../Navbar/Navbar";
import {useNavigate} from "react-router-dom";
import "../PageStyle.css"
import {renderToString} from "react-dom/server";
import moment from "moment";

function CustomerRezervationForCottagePage() {
    const navigate = useNavigate();
    const {register, handleSubmit, formState: {errors}, setValue} = useForm();
    const {startDate, endDate, productId, eventId} = useParams();


    // Calculate the difference in milliseconds
    const millisecondsInDay = 24 * 60 * 60 * 1000;
    // Convert milliseconds to days
    const daysReservationLength = Math.round((new Date(endDate) - new Date(startDate)) / millisecondsInDay);

    const [pricePerReservation, setPricePerReservation] = useState(null);

    const [addedData, setAddedData] = useState([]); // State to store data from CottageItem

    const getProductDayPrice = async () => {
        try {
            setIsLoading(true)
            let response = await axios.get(`${process.env.REACT_APP_SERVER_URL}/api/cottage/${productId}/price/event/${eventId}/startDate/${startDate}/endDate/${endDate}`)

            if (response.data.isEvent === true) {
                setPricePerReservation(response.data.rentPrice)
            } else {
                setPricePerReservation(response.data.rentPrice)
            }
        } catch (error) {
            console.error('Error while fetching data:', error);
        } finally {
            setIsLoading(false)
        }
    }
    useEffect(() => {
        getProductDayPrice();
        window.scrollTo(0, 0) // Scroll to top of the page

        // Set default values for the form
        setValue("childrenCount",0)
        setValue("adultsCount",0)
    }, []);

    const [isLoading, setIsLoading] = useState(true)
    const onSubmit = async (data) => {
        const reservationData = {
            ...data,
            startDate: startDate,
            endDate: endDate,
            items: addedData,
        };
        try {
            setIsLoading(true)
            // Make an HTTP POST request to send the form data to the server
            const response = await axios.post(`${process.env.REACT_APP_SERVER_URL}/api/reservations/cottage/${productId}/event/${eventId}/create`, reservationData);
            Swal.fire(
                'Úspěšně uloženo ',
                `${response.data.message}`,
                'success'
            )
            navigate("/");
        } catch (error) {
            if (error.response.data?.errors) {
                Swal.fire({
                    icon: 'error',
                    title: 'Nastala chyba!',
                    html: `<div class="alert alert-danger">${renderToString(
                        <div>
                            {error.response.data?.errors.map((field, index) => (
                                <p key={index}>{field?.msg}</p>
                            ))}
                        </div>
                    )}</div> `
                });
            } else {
                Swal.fire(
                    'Nastala chyba!',
                    ` ${error.response.data?.message} `,
                    'error'
                )
            }
        } finally {
            setIsLoading(false)
        }
    };

    // Function to receive data from CottageItem
    const receiveDataFromCottageItem = (data) => {
        setAddedData((prevData) => {
            const newData = [...prevData]
            console.log(newData)
            if (data?.delete) {
                return newData.filter((item) => item.item_id !== data.item_id);
            } else {
                const index = newData.findIndex((item) => item.item_id === data.item_id)

                if (index !== -1) {
                    newData[index] = data
                } else {
                    newData.push(data)
                }

                return newData;
            }
        })
    };

    return (
        <>
            <Navbar/>
            <div className="container front-page w-100">
                {isLoading ? (
                    <LoadingBar/>
                ) : (
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <h3>Vyplnění osobních údajů pro rezervaci</h3>
                        <p>Poznámka: Chalupa je vždy předáná od 15:00 hodin a  nejpozději v 11:00 hodin musí být opuštěna</p>

                        <div className="form-row">
                            <div className="form-group col-md-6 ">
                                <label htmlFor="">Začátek</label>
                                <div className="form-control btn btn-success">{formattedDate(startDate)}</div>
                            </div>
                            <div className="form-group col-md-6">
                                <label htmlFor="">Konec</label>
                                <div className="form-control btn btn-danger">{formattedDate(endDate)}</div>
                            </div>
                        </div>

                        <div className="form-row">
                            <div className="form-group col-md-6">
                                <label htmlFor="">Jméno</label>
                                <input {...register("firstname", {required: "Povinné pole"})} className="form-control"/>
                            </div>
                            <div className="form-group col-md-6">
                                <label htmlFor="">Příjmení</label>
                                <input {...register("lastname", {required: "Povinné pole"})} className="form-control"/>
                            </div>
                        </div>
                        <div className="form-row">
                            <div className="form-group col-md-6">
                                <label htmlFor="">Email</label>
                                <input type={"email"} {...register("email", {required: "Povinné pole"})}
                                       className="form-control"/>
                            </div>
                            <div className="form-group col-md-6 z-0">
                                <label htmlFor="">Telefon</label>
                                <input type={"number"} {...register("tel", {required: "Povinné pole"})}
                                       className="form-control"/>
                            </div>
                        </div>
                        <div className="form-group">
                            <label htmlFor="" >Počet dospělých</label>
                            <input type={"number"} {...register("adultsCount", {required: "Povinné pole"})}
                                   className="form-control"/>
                        </div>
                        <div className="form-group">
                            <label htmlFor="">Počet dětí</label>
                            <input type={"number"} {...register("childrenCount", {required: "Povinné pole"})}
                                   className="form-control"/>
                        </div>
                        <div className="form-group col-md-12" style={{paddingLeft: "0px", paddingRight: "0px"}}>
                            <label htmlFor="inputAddress2">Záloha, kterou je nutné uhradit online-převodem</label>
                            <div
                                className="form-control form-control">{CZKCurrency.format(parseInt(pricePerReservation) / 2)}</div>
                        </div>
                        <div className="form-group col-md-12" style={{paddingLeft: "0px", paddingRight: "0px"}}>
                            <label htmlFor="inputAddress2">Cena za {daysReservationLength} noc/noci</label>
                            <div
                                className="form-control form-control">{CZKCurrency.format(pricePerReservation)}</div>
                        </div>
                        <div className="">
                            Chcete přidat další produkt k rezervaci ?
                            <CottageItem cottageId={productId}
                                         startDate={startDate}
                                         endDate={endDate}
                                         onDataReceived={receiveDataFromCottageItem} // Pass the function as a prop
                                         itemsReservations={addedData}
                            ></CottageItem>
                        </div>
                        <div className="form-group">
                            <input type={"submit"} className="rezervation-button" value={"Rezervovat"}
                                   name="send-data-form"/>
                            <Link to={"/"} className="text-light">
                                <button className="go-back-button m-1" type={"button"}>
                                    Zpět
                                </button>
                            </Link>
                        </div>
                    </form>
                )}
            </div>
        </>
    )
}

export default CustomerRezervationForCottagePage;
