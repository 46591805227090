// const data = [{
//     id: 2,
//     image: "/cottageImages/l1.jpg",
//     category: "LÉTO",
// }, {
//     id: 3,
//     image: "/cottageImages/l2.jpg",
//     category: "LÉTO",
// }, {
//     id: 4,
//     image: "/cottageImages/l3.jpg",
//     category: "LÉTO",
// }, {
//     id: 5,
//     image: "/cottageImages/l4.jpg",
//     category: "LÉTO",
// }, {
//     id: 6,
//     image: "/cottageImages/l5.jpg",
//     category: "LÉTO",
// }, {
//     id: 7,
//     image: "/cottageImages/l6.jpg",
//     category: "LÉTO",
// }, {
//     id: 8,
//     image: "/cottageImages/l7.jpg",
//     category: "LÉTO",
// }, {
//     id: 9,
//     image: "/cottageImages/i1-small.png",
//     category: "INTERIÉR",
// }, {
//     id: 10,
//     image: "/cottageImages/i2.jpg",
//     category: "INTERIÉR",
// }, {
//     id: 11,
//     image: "/cottageImages/i6.jpg",
//     category: "INTERIÉR",
// }, {
//     id: 12,
//     image: "/cottageImages/i7.jpg",
//     category: "INTERIÉR",
// }, {
//     id: 13,
//     image: "/cottageImages/i8.jpg",
//     category: "INTERIÉR",
// }, {
//     id: 14,
//     image: "/cottageImages/i9.jpg",
//     category: "INTERIÉR",
// }, {
//     id: 15,
//     image: "/cottageImages/i10.jpg",
//     category: "INTERIÉR",
// }, {
//     id: 16,
//     image: "/cottageImages/i1.jpg",
//     category: "INTERIÉR",
// }, {
//     id: 17,
//     image: "/cottageImages/i12.jpg",
//     category: "INTERIÉR",
// }, {
//     id: 18,
//     image: "/cottageImages/i13.jpg",
//     category: "INTERIÉR",
// }, {
//     id: 19,
//     image: "/cottageImages/i14.jpg",
//     category: "INTERIÉR",
// }, {
//     id: 20,
//     image: "/cottageImages/i15.jpg",
//     category: "INTERIÉR",
// }, {
//     id: 21,
//     image: "/cottageImages/i16.jpg",
//     category: "INTERIÉR",
// }, {
//     id: 22,
//     image: "/cottageImages/i17.jpg",
//     category: "INTERIÉR",
// },
//     {
//         id: 23,
//         image: "/cottageImages/i3.jpg",
//         category: "INTERIÉR",
//     }, {
//         id: 24,
//         image: "/cottageImages/i4.jpg",
//         category: "INTERIÉR",
//     }, {
//         id: 25,
//         image: "/cottageImages/i5.jpg",
//         category: "INTERIÉR",
//     },
// ]
// export default data;


const data = [{
    id: 1,
    image: "/cottageImages/into_image.jpeg",
    category: "LÉTO",
},{
    id: 2,
    image: "/cottageImages/l1 Medium.jpeg",
    category: "LÉTO",
}, {
    id: 3,
    image: "/cottageImages/l2 Medium.jpeg",
    category: "LÉTO",
}, {
    id: 4,
    image: "/cottageImages/l3 Medium.jpeg",
    category: "LÉTO",
}, {
    id: 5,
    image: "/cottageImages/l4 Medium.jpeg",
    category: "LÉTO",
}, {
    id: 6,
    image: "/cottageImages/l5 Medium.jpeg",
    category: "LÉTO",
}, {
    id: 7,
    image: "/cottageImages/l6 Medium.jpeg",
    category: "LÉTO",
}, {
    id: 8,
    image: "/cottageImages/l7 Medium.jpeg",
    category: "LÉTO",
}, {
    id: 9,
    image: "/cottageImages/i1 Medium.jpeg",
    category: "INTERIÉR",
}, {
    id: 10,
    image: "/cottageImages/i2 Medium.jpeg",
    category: "INTERIÉR",
}, {
    id: 11,
    image: "/cottageImages/i6 Medium.jpeg",
    category: "INTERIÉR",
}, {
    id: 12,
    image: "/cottageImages/i7 Medium.jpeg",
    category: "INTERIÉR",
}, {
    id: 13,
    image: "/cottageImages/i8 Medium.jpeg",
    category: "INTERIÉR",
}, {
    id: 14,
    image: "/cottageImages/i9 Medium.jpeg",
    category: "INTERIÉR",
}, {
    id: 15,
    image: "/cottageImages/i10 Medium.jpeg",
    category: "INTERIÉR",
}, {
    id: 16,
    image: "/cottageImages/i1 Medium.jpeg",
    category: "INTERIÉR",
}, {
    id: 17,
    image: "/cottageImages/i12 Medium.jpeg",
    category: "INTERIÉR",
}, {
    id: 18,
    image: "/cottageImages/i13 Medium.jpeg",
    category: "INTERIÉR",
}, {
    id: 19,
    image: "/cottageImages/i14 Medium.jpeg",
    category: "INTERIÉR",
}, {
    id: 20,
    image: "/cottageImages/i15 Medium.jpeg",
    category: "INTERIÉR",
}, {
    id: 21,
    image: "/cottageImages/i16 Medium.jpeg",
    category: "INTERIÉR",
}, {
    id: 22,
    image: "/cottageImages/i17 Medium.jpeg",
    category: "INTERIÉR",
},
    {
        id: 23,
        image: "/cottageImages/i3 Medium.jpeg",
        category: "INTERIÉR",
    }, {
        id: 24,
        image: "/cottageImages/i4 Medium.jpeg",
        category: "INTERIÉR",
    }, {
        id: 25,
        image: "/cottageImages/i5 Medium.jpeg",
        category: "INTERIÉR",
    },
]
export default data;

