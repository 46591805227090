import React, {useState} from 'react';
import "./MessageForm.css"
import axios from "axios";
import "../PageStyle.css"
import Swal from "sweetalert2";

function MessageForm(props) {

    const[name, setName] = useState()
    const[lastname, setLastname] = useState()
    const[email, setEmail] = useState()
    const[message, setMessage] = useState()

    const sendMessage = (e) => {
        e.preventDefault();
        try{
            axios.post((`${process.env.REACT_APP_SERVER_URL}/api/contact/send-email`), {name, lastname, email, message}).then((response) => {
                console.log(response.data.message);
                    Swal.fire({
                        position: "center",
                        icon: "success",
                        title: response.data.message,
                        showConfirmButton: false,
                        timer: 1000
                    });
            });
        }catch(error){
            Swal.fire({
                icon: "error",
                title: "Oops...",
                text: error.message,
                footer: '<a href="#">Why do I have this issue?</a>'
            });
            console.error(error)
        }
    }

    return (
        <div
            className="container front-page email-message-page d-flex flex-column w-100 justify-content-center align-content-center"
            id="email-message-page">
            <h1>KONTAKTUJTE NÁS</h1>
            <div className="form-container">
                <div className="text">
                    Contact us Form
                </div>
                <form>
                    <div className="form-row">
                        <label>Jméno</label>
                        <div className="input-data m-1">
                            <input type="text" required onChange={(e) => setName(e.target.value)}/>
                            <div className="underline"></div>
                        </div>
                    </div>
                        <div className="form-row">
                        <label>Příjmení</label>
                        <div className="input-data m-1">
                            <input type="text" required onChange={(e) => setLastname(e.target.value)} />
                            <div className="underline"></div>
                        </div>
                    </div>
                    <div className="form-row">
                        <label>Email</label>
                        <div className="input-data m-1">
                            <input type="text" required onChange={(e) => setEmail(e.target.value)}/>
                            <div className="underline"></div>
                        </div>
                    </div>
                    <div className="form-row">
                        <label>Zpráva</label>
                        <div className="input-data textarea m-1">
                            <textarea rows="12" cols="100" required onChange={(e) => setMessage(e.target.value)}/>
                            <br/>
                            <div className="underline"></div>
                            <br/>
                        </div>
                    </div>

                    <div className="form-row ">
                        <div className="submit-btn">
                            <div className="input-data">
                                <div className="inner"></div>
                                <input type="submit" value="submit" value={"ODESLAT"} onClick={sendMessage}/>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    );
}

export default MessageForm;
