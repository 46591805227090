// PictureOpenModal.js
import React, {useEffect, useState} from 'react';
import {Modal} from 'react-bootstrap';
import {FaChevronRight, FaChevronLeft} from "react-icons/fa";
import {FaXmark} from "react-icons/fa6";
import Data from "../CottageItem/DataItems";
import 'swiper/css';
import 'swiper/css/navigation';

function PictureOpenModal({clickedImageCategoryId,setClickedImageCategoryId}) {
    const [images, setImages] = useState([]);
    const [clickedImage, setClickedImage] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);

    const getImageByPosition = (clickedImagePosition) => {
        if (clickedImagePosition < 0) {
            setClickedImage(images.length - 1);
            return images[images.length - 1].image;
        }
        if (clickedImagePosition >= images.length) {
            setClickedImage(0);
            return images[0].image;
        }
        return images[clickedImagePosition].image;
    };

    useEffect(() => {
        if (clickedImageCategoryId !== null) {
            setIsModalOpen(true)
            const filteredCategory = Data.filter((item) => item.categoryId === clickedImageCategoryId);
            setImages(filteredCategory);
            setClickedImage(0); // Default to the first image in the category
        }
    }, [clickedImageCategoryId !== null]);

    return (
        <div>
            {clickedImageCategoryId !== null && isModalOpen && (
                <div className={"container"}>
                    <Modal show={true} onHide={() => setClickedImageCategoryId(null)} centered size="xl" dialogClassName="">
                        <Modal.Header className={"p-0 m-0 w-100 flex justify-content-end py-2 px-3"}
                                      style={{cursor: "pointer"}}
                                      onClick={() => setClickedImageCategoryId(null)}>
                            <FaXmark size={20} className={"p-10"}/>
                        </Modal.Header>
                        <Modal.Body className="p-2" style={{
                            width: "100%",
                            height: "80%",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center"
                        }}>
                            <div className="d-flex justify-content-center align-items-center">
                                <div onClick={() => setClickedImage(prev => (prev - 1 + images.length) % images.length)}
                                     style={{cursor: "pointer", color: "lightgray"}} className={"p-10 mx-1"}>
                                    <FaChevronLeft size={40}/>
                                </div>

                                {images.length > 0 && (
                                    <img src={`${getImageByPosition(clickedImage)}`} alt={`Nature ${clickedImage + 1}`}
                                         className="img-fluid"
                                         style={{objectFit: 'cover', width: "85%", height: "100%"}}/>
                                )}

                                <div onClick={() => setClickedImage(prev => (prev + 1) % images.length)}
                                     style={{cursor: "pointer", color: "lightgray"}} className={"p-10 mx-1"}>
                                    <FaChevronRight size={40}/>
                                </div>
                            </div>
                        </Modal.Body>
                    </Modal>
                </div>
            )}
        </div>
    );
}

export default PictureOpenModal;





