import React from 'react';
import './CottageItem.css'
import ReadMoreLessFormatted from "../ReadMoreLessFormatted";

const CottageItemBox = ({isItemReserved, item, itemsSize, deleteItem, openModal, setImageCategoryId}) => {

    let { item_id: itemId, name: itemName} = item.Item;

    return (
        <div className="card" key={itemId}>
            <div
                className="d-flex justify-content-md-end"
                style={{color: isItemReserved ? 'green' : 'red', fontSize: '30px'}}
            >
                {isItemReserved ? <i className="gg-check" style={{fontSize: '30px'}}></i> : null}
            </div>
            <h5>{itemName}</h5>

            <ReadMoreLessFormatted item={item.Item} lessLenght={0} />

            <div className={"d-flex flex-column h-100 align-bottom justify-content-end w-100"}>
                <div style={{cursor: "pointer"}}
                     onClick={() => setImageCategoryId(itemId)}>
                    <img src={`/images/${item.Item.image_url}`} className={"rounded"}/>
                </div>
                <p className={"py-2"}>
                    <b> {item.Item.price_per_day} Kč/den</b>
                </p>
                <div className={"w-100"}>
                    {isItemReserved ? (
                        <button onClick={() => deleteItem(item)} type="button"
                                className="remove">
                            Odstranit
                        </button>
                    ) : (
                        <button onClick={() => openModal(item)} type="button"
                                className="send w-100">
                            Přidat
                        </button>
                    )}
                </div>
            </div>
        </div>
    );
};

export default CottageItemBox;
