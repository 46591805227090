// import React, {useState} from 'react';
// import "./InformationPage.css"
// import "../PageStyle.css"
// import {FaAddressCard, FaTruckMonster, FaSoap} from "react-icons/fa";
// import 'swiper/css';
// import 'swiper/css/navigation';
//
//
// import {Swiper, SwiperSlide} from 'swiper/react';
//
// import {  Navigation } from 'swiper/modules';
// import ReactReadMoreReadLess from "react-read-more-read-less";
//
// function InformationPage(props) {
//
//     return (
//         <div className="container front-page information-page" id="information-page" alt={"Chalupa Komorni Lhotka"}>
//             <div className="introduction" alt={"Chalupa Komorni Lhotka"}>
//                 <h1>Informace o pronájmu </h1>
//                 <ReactReadMoreReadLess
//                     charLimit={300}
//                     readMoreText={"Číst více"}
//                     readLessText={"Číst méně"}
//                     readMoreStyle={{color: 'black', cursor: 'pointer', fontWeight: 'bold'}}
//                     readLessStyle={{color: 'black', cursor: 'pointer', fontWeight: 'bold'}}
//                 >
//                         Naše celodřevěná roubenka poskytne ubytování pro maximálně 10 osob.
//                         V prvním patře najdete dvě prostorné ložnice. Větší se dvěma manželskými lůžky a menší s manželskou
//                         postelí a jednolůžkem. V prvním patře se také nachází koupelna s velkou rohovou vanou a samostatné
//                         WC.
//                         V přízemí pak prostorná a plně vybavená kuchyně s velkým stolem a televizí. Samozřejmostí je
//                         kompletní vybavení, včetně drogerie, tablet do myčky, utěrek apod.
//                         Obývací místnost se dvěma velkými gauči a kamny, navodí tu správnou atmosféru především v podzimních
//                         a zimních měsících. Nechybí ani dětský koutek a spousta společenských her k zapůjčení, včetně
//                         knihovny pro děti ale i dospělé.
//                         A při plné obsazenosti určitě oceníte naší druhou koupelnu se sprchovým koutem a toaletou také v
//                         přízemí.
//                         K dispozici v chalupě budete mít veškeré vybavení v kuchyni, ručníky, toaletní papír a kompletní
//                         povlečení. Kvalitní Wi-Fi připojení a televize v kuchyni i v obývacím pokoji, kde máte k dispozici
//                         Netflix.
//                         Pejsci jsou povoleni za příplatek za úklid 300 kč za celý pobyt.
//                         Soukromé parkování u chalupy pro dvě auta.
//
//                 </ReactReadMoreReadLess>
//             </div>
//             <div className="cottage-info">
//                 <div className="text-information">
//                     <div className="single-information d-flex py-3 align-items-center">
//                         <div className="icon-detail">
//                             <FaAddressCard/>
//                         </div>
//                         <div className={"description"}>
//                             <h5>Adresa</h5>
//                             <p>Komorní Lhotka 13</p>
//                         </div>
//                     </div>
//                     <div className="single-information d-flex py-3 align-items-center">
//                         <div className="icon-detail">
//                             <FaTruckMonster/>
//                         </div>
//                         <div className={"description"}>
//                             <h5>Další věci k pronájmu</h5>
//                             <p>V rámci pobytu je možné si pronajmout: <strong>Elektrokolo, Čtyřkolku</strong></p>
//                         </div>
//                     </div>
//                     <div className="single-information d-flex py-3 align-items-center">
//                         <div className="icon-detail">
//                             <FaSoap/>
//                         </div>
//                         <div className={"description"}>
//                             <h5>Hygiena</h5>
//                             <p>Plně vybavená kuchyně, pračka, drogérie, utěrky</p>
//                         </div>
//                     </div>
//                 </div>
//
//                 <Swiper navigation={true} modules={[Navigation]} className="mySwiper">
//                         <SwiperSlide>
//                             <img src={"/images/crussis.jpeg"}/>
//                         </SwiperSlide>
//                         <SwiperSlide>
//                             <img src={"/images/crussisolguera.jpeg"}/>
//                         </SwiperSlide>
//                         <SwiperSlide>
//                             <img src={"/images/crussisoneguera.jpeg"}/>
//                         </SwiperSlide>
//                         <SwiperSlide>
//                             <img src={"/images/haibike.jpeg"}/>
//                         </SwiperSlide>
//                         <SwiperSlide>
//                             <img src={"/images/tgb.jpeg"}/>
//                         </SwiperSlide>
//                 </Swiper>
//             </div>
//         </div>
//     );
// }
//
// export default InformationPage;
import React, {useState} from 'react';
import {Swiper, SwiperSlide} from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/thumbs';
import './InformationPage.css'


import "./InformationPage.css"
import "../PageStyle.css"
import {FaAddressCard, FaTruckMonster, FaSoap} from "react-icons/fa";
import 'swiper/css';
import 'swiper/css/navigation';
import ReactReadMoreReadLess from "react-read-more-read-less";
import {Link} from "react-router-dom";


export default function InformationPage() {
    const [thumbsSwiper, setThumbsSwiper] = useState(null)

    return (
        <div className="container front-page information-page" id="information-page" alt={"Chalupa Komorni Lhotka"}>
            <div className="introduction" alt={"Chalupa Komorni Lhotka"}>
                <h1>Informace o pronájmu </h1>
                <ReactReadMoreReadLess
                    charLimit={300}
                    readMoreText={"Číst více"}
                    readLessText={"Číst méně"}
                    readMoreStyle={{color: 'black', cursor: 'pointer', fontWeight: 'bold'}}
                    readLessStyle={{color: 'black', cursor: 'pointer', fontWeight: 'bold'}}
                >
                    Naše celodřevěná roubenka poskytne ubytování pro maximálně 10 osob.
                    V prvním patře najdete dvě prostorné ložnice. Větší se dvěma manželskými lůžky a menší s manželskou
                    postelí a jednolůžkem. V prvním patře se také nachází koupelna s velkou rohovou vanou a samostatné
                    WC.
                    V přízemí pak prostorná a plně vybavená kuchyně s velkým stolem a televizí. Samozřejmostí je
                    kompletní vybavení, včetně drogerie, tablet do myčky, utěrek apod.
                    Obývací místnost se dvěma velkými gauči a kamny, navodí tu správnou atmosféru především v podzimních
                    a zimních měsících. Nechybí ani dětský koutek a spousta společenských her k zapůjčení, včetně
                    knihovny pro děti ale i dospělé.
                    A při plné obsazenosti určitě oceníte naší druhou koupelnu se sprchovým koutem a toaletou také v
                    přízemí.
                    K dispozici v chalupě budete mít veškeré vybavení v kuchyni, ručníky, toaletní papír a kompletní
                    povlečení. Kvalitní Wi-Fi připojení a televize v kuchyni i v obývacím pokoji, kde máte k dispozici
                    Netflix.
                    Pejsci jsou povoleni za příplatek za úklid 300 kč za celý pobyt.
                    Soukromé parkování u chalupy pro dvě auta.
                </ReactReadMoreReadLess>
            </div>
            <div className="cottage-info">
                <div className="text-information">
                    <div className="single-information d-flex py-3 align-items-center">
                        <div className="icon-detail">
                            <FaAddressCard/>
                        </div>
                        <div className={"description"}>
                            <h5>Adresa</h5>
                            <p>Komorní Lhotka 13</p>
                        </div>
                    </div>
                    <div className="single-information d-flex py-3 align-items-center">
                        <div className="icon-detail">
                            <FaTruckMonster/>
                        </div>
                        <div className={"description"}>
                            <h5>Další věci k pronájmu</h5>
                            <Link to={"/items-info-page"} className={"text-dark"}>V rámci pobytu je možné si pronajmout: <strong>Elektrokola, Čtyřkolku</strong></Link>
                        </div>
                    </div>
                    <div className="single-information d-flex py-3 align-items-center">
                        <div className="icon-detail">
                            <FaSoap/>
                        </div>
                        <div className={"description"}>
                            <h5>Hygiena</h5>
                            <p>Plně vybavená kuchyně, pračka, drogérie, utěrky</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
        ;
}


