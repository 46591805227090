import React, {useEffect} from 'react';
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import {useForm} from "react-hook-form";

function UniversalModal({isOpen, handleClose, title, inputs, action, modalFormData, unique_id}) {
    const {register, handleSubmit, reset, setValue} = useForm();
    // Set default values into form
    useEffect(() => {
        inputs.map((input) => {
            console.log(input?.value)
            setValue(input.name, input?.value)
        })
    }, [isOpen])

    const onSubmit = (data) => {
        console.log(data, " this data")
        if(action === "create") {
            modalFormData(data)
        }
        // if(action === "update") {
        //     modalFormData({
        //         ...data,
        //         identicator:
        //     })
        // }
        if(action === "delete") {
            modalFormData(unique_id)
        }

        reset()
    };

    return (
        <div>
            <Modal
                show={isOpen}
                onHide={handleClose}
                backdrop="static"
                keyboard={false}
            >
                <Modal.Header style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                }}>
                    <Modal.Title> {title}</Modal.Title>
                </Modal.Header>
                <Modal.Body className="d-flex flex-column align-items-center">
                    <form onSubmit={handleSubmit(onSubmit)} className={"w-100"}>
                        {
                            inputs.map((input, index) => (
                                    <div className="w-100" key={index}>
                                        <label>{input.label}</label>
                                        <input
                                            {...register(input.name, {required: input.required})}
                                            type={input.type}
                                            placeholder={input.placeholder}
                                            className="form-control w-100"
                                            disabled={input?.readOnly}
                                        />
                                    </div>
                                )
                            )
                        }
                    </form>
                </Modal.Body>
                <Modal.Footer style={{display: "flex", justifyContent: "flex-end"}}>
                    <div className="d-flex">
                        {
                            action === "delete" ? (
                                <Button variant={"danger"} onClick={() => handleSubmit(onSubmit)()} className="mx-1">
                                    Smazat
                                </Button>
                            ) : action === "create" ? (
                                <Button variant={"success"} onClick={() => handleSubmit(onSubmit)()} className="mx-1">
                                    Uložit
                                </Button>
                            ) : null // You might want to add a default case or handle other values of 'action'
                        }
                        <Button variant={"primary"} onClick={handleClose}>
                            Zavřít
                        </Button>
                    </div>
                </Modal.Footer>
            </Modal>
        </div>
    );
}

export default UniversalModal;