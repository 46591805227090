import React, {useEffect, useState} from 'react';
import {FaBars, FaTimes, FaPhoneAlt, FaEnvelopeOpenText, FaPhone, FaMapPin} from "react-icons/fa";
import "./Navbar.css"
import {Button} from "react-bootstrap";
import {useRef} from "react";
import {Link as LinkRouterDom, useParams} from "react-router-dom";
import {useTokenVerification} from "../../utils/jwtTokenHelper";

function Navbar() {
    const navRef = useRef();
    const [isNavBtnHidden, setIsNavBtnHidden] = useState(false);
    const [phone, setPhone] = useState("+420 608 597 418");
    const [displayAbsoluteIcons, setDisplayAbsoluteIcons] = useState(false)
    const {cottageId} = useParams()
    console.log(cottageId + " NAVBAR")

    const showNavbar = () => {
        navRef.current.classList.toggle("responsive-nav")
        setIsNavBtnHidden(!isNavBtnHidden)
    }
    const hideNavbar = () => {
        if (window.innerWidth <= 1100) { // I need to find a better solution
            setIsNavBtnHidden(!isNavBtnHidden)
            setDisplayAbsoluteIcons(true)
        }
        navRef.current.classList.remove("responsive-nav")
    }

    const Callto = ({phone, children}) => {
        return <a href={`tel:${phone}`} style={{padding: "0px", margin: "0px"}}>{children}</a>;
    };


    // Clear localstorage if token is not authenticated
    const isAuthenticated = localStorage.getItem("token");
    if (useTokenVerification() === false) {
        localStorage.clear();
    }

    const handleClickScroll = (pageID) => {
        const element = document.getElementById(pageID);
        if (element) {
            // 👇 Will scroll smoothly to the top of the next section
            element.scrollIntoView({behavior: 'smooth'});
        }
        console.log("clicked")
    };

    const handleResize = () => {
        if (window.innerWidth <= 1100) {
            setDisplayAbsoluteIcons(true);
        } else {
            setDisplayAbsoluteIcons(false);
        }
    };
    useEffect(() => {
        window.addEventListener('resize', handleResize);

        // Call handleResize initially to set the correct state based on initial window size
        handleResize();

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);


    return (
        <header>
            {
                displayAbsoluteIcons === true && (
                    <div class={"position-absolute"} style={{left:"10px", top:"20px"}}>
                        <a href={"tel: +420 608 597 418"} className={"position-absolute start-0 text-light border-right"} style={{left: "10px", height:"50px", padding:"0px 10px"}}>
                            <FaPhone size={"22"} className={"text-center w-100"}/>
                            <p>Kontakt</p>
                        </a>
                        <a href={"https://www.google.cz/maps/place/Komorn%C3%AD+Lhotka+13,+739+53+Komorn%C3%AD+Lhotka/@49.6564931,18.5251053,17z/data=!3m1!4b1!4m6!3m5!1s0x471408c18d7047cb:0xe7fdb3c044af90bd!8m2!3d49.6564931!4d18.5276802!16s%2Fg%2F11c1b2rc91?entry=ttu"}
                           target={"_blank"} className={"position-absolute start-0 text-light border-right"} style={{left: "80px", height:"50px", padding:"0 20px"}}>
                            <FaMapPin size={"22"} className={"text-center w-100"}/>
                            <p>Poloha</p>
                        </a>
                    </div>
                )
            }
            <nav ref={navRef}>
                <div className="my-navbar">
                    {
                        isAuthenticated ? (
                            <div className="d-flex justify-content-center align-content-center navbar-admin">
                                <LinkRouterDom to={`/`} className="link">
                                    <p className={"mb-0"}>Úvod</p>
                                </LinkRouterDom>
                                <LinkRouterDom to={"/admin/reservation/overview"} className="link">
                                    <p className={"mb-0"}>Přehled Rezervací</p>
                                </LinkRouterDom>
                                <LinkRouterDom to={"/admin/calendar-settings"} className="link">
                                    <p className={"mb-0"}>Nastavení kalendáře</p>
                                </LinkRouterDom>
                                <LinkRouterDom to={"/admin/logout"} className="link">
                                    <p className={"mb-0"}>Odhlásit se</p>
                                </LinkRouterDom>
                            </div>
                        ) : (
                            <>
                                <div className="links">
                                    <LinkRouterDom onClick={() => {
                                        handleClickScroll("information-page")
                                    }} to={`/#information-page`} style={{position: "relative"}}>
                                        <div className="" onClick={hideNavbar}>
                                            Informace
                                            <span className="dot"></span>
                                        </div>
                                    </LinkRouterDom>
                                    <LinkRouterDom onClick={() => {
                                        handleClickScroll("interesting-facts-page")
                                    }} to={`/#interesting-facts-page`} style={{position: "relative"}}>
                                        <div className="" onClick={hideNavbar}>
                                            Zajímavosti
                                            <span className="dot"></span>
                                        </div>
                                    </LinkRouterDom>
                                    <LinkRouterDom onClick={() => {
                                        handleClickScroll("rezervation-page")
                                    }} to={`/#rezervation-page`} style={{position: "relative"}}>
                                        <div className="" onClick={hideNavbar}>
                                            Rezervace
                                            <span className="dot"></span>
                                        </div>
                                    </LinkRouterDom>
                                    <LinkRouterDom onClick={() => {
                                        handleClickScroll("pricing-page")
                                    }} to={`/#pricing-page`} style={{position: "relative"}}>
                                        <div className="" onClick={hideNavbar}>
                                            Ceník
                                            <span className="dot"></span>
                                        </div>
                                    </LinkRouterDom>
                                    <LinkRouterDom onClick={() => {
                                        handleClickScroll("gallery-page")
                                    }} to={`/#gallery-page`} style={{position: "relative"}}>
                                        <div className="" onClick={hideNavbar}>
                                            Galerie
                                            <span className="dot"></span>
                                        </div>
                                    </LinkRouterDom>
                                </div>
                                <div className="contact-info d-flex align-items-center">
                                    <div className="phone px-3" style={{padding: "15px"}}><FaPhoneAlt className="h-25"/>
                                        <Callto
                                            phone={phone}>{phone}</Callto>
                                    </div>
                                    <div className="email"
                                         style={{
                                             background: "orange",
                                             padding: "15px",
                                             fontSize: "18px",
                                             borderRadius: "22px"
                                         }}>
                                        <LinkRouterDom onClick={() => {
                                            handleClickScroll("email-message-page")
                                            hideNavbar()
                                        }} to={"/#email-message-page"}>
                                            Zeptat se
                                        </LinkRouterDom>
                                    </div>
                                </div>
                            </>
                        )
                    }

                </div>
                <Button onClick={showNavbar} className="nav-btn nav-close-btn" variant="none">
                    <FaTimes/>
                </Button>
            </nav>
            <Button onClick={showNavbar} className={`nav-btn ${isNavBtnHidden ? 'hidden' : ''}`} variant="none">
                <FaBars/>
            </Button>
        </header>
    );
}

export default Navbar;

