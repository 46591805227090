// import React from 'react';
// import ReactDOM from 'react-dom/client';
// import './index.css';
// import App from './App';
// import reportWebVitals from './reportWebVitals';
//
// const root = ReactDOM.createRoot(document.getElementById('root'));
// root.render(
//     <React.StrictMode>
//             <App/>
//     </React.StrictMode>
// );
//
// // If you want to start measuring performance in your app, pass a function
// // to log results (for example: reportWebVitals(console.log))
// // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();


// uses rendering to improve performance
// import React from 'react';
// import ReactDOM from 'react-dom'; // Correct import
// import './index.css';
// import App from './App';
//
// const rootElement = document.getElementById('root');
//
// if (rootElement.hasChildNodes()) {
//     ReactDOM.hydrate(<App />, rootElement);
// } else {
//     ReactDOM.render(<App />, rootElement);
// }


import React from 'react';
import { createRoot, hydrateRoot } from 'react-dom/client'; // Correct import
import './index.css';
import App from './App';

const rootElement = document.getElementById('root');

if (rootElement.hasChildNodes()) {
    hydrateRoot(rootElement, <App />);
} else {
    const root = createRoot(rootElement);
    root.render(<App />);
}


