import React from 'react';
import {CZKCurrency} from "../../../utils/helper";

function EventColorBox({eventsInformation}) {
    return (
        <div className={"event-box d-flex flex-column align-items-center justify-content-center"}
             style={{width: "350px"}}>
            {
                Object.keys(eventsInformation).map((eventId) => (
                    <div className={"d-flex flex-row align-items-center justify-content-center w-100"} key={eventId}>
                        <div style={{
                            backgroundColor: "purple",
                            width: "20px",
                            height: "20px",
                            marginRight: "auto"
                        }}></div>
                        <div className="px-3 py-2" style={{textAlign: "left", width: "330px"}}>
                            <p className={"p-0 m-0"}>{eventsInformation[eventId][0].label} </p>
                            <p className={"p-0 m-0"}>Cena za pobyt: <strong>{CZKCurrency.format(eventsInformation[eventId][0].price)}</strong></p>
                        </div>
                    </div>
                ))
            }
        </div>
    );
}

export default EventColorBox;