import React, {useState, useEffect} from 'react';
import axios from "axios";
import {CZKCurrency} from "../../utils/helper";

function PricingPage(props) {
    const [monthsInfo, setMonthsInfo] = useState([])

    const getMonthPricing = async () => {
        try{
            const response = await axios.get(`${process.env.REACT_APP_SERVER_URL}/api/months/cottage/1/all-months-prices`);
            setMonthsInfo(response.data.monthsInfo)
            console.log(response.data)
        }catch(error) {

        }
    }

    useEffect(() => {
         getMonthPricing()
    },[])
    return (
        <div className="container front-page pricing-page d-flex flex-column w-100 justify-content-center align-content-center" id={"pricing-page"}>
            <h1>Ceník pro následující měsíce</h1>

            <table className="table mt-3">
                <thead>
                <tr>
                    <th scope="col">Rok</th>
                    <th scope="col">Měsíc</th>
                    <th scope="col">Cena za 1 noc</th>
                </tr>
                </thead>
                <tbody>
                {
                    monthsInfo.map((month, index) => (
                        <tr key={index}>
                            <td>{month.year_number}</td>
                            <td>{month.Month.month_name}</td>
                            <td>{CZKCurrency.format(month.price_per_night)}</td>
                        </tr>
                    ))
                }
                </tbody>
            </table>
        </div>
    );
}

export default PricingPage;
