import {useState, useEffect} from "react";
import axios from "axios";

export const useTokenVerification = () => {
    const [isValidToken, setIsValidToken] = useState(null);

    useEffect(() => {
        const verifyJwtToken = async () => {
            const token = localStorage.getItem('token');

            if (token) {
                try {
                    const response = await axios.post(`${process.env.REACT_APP_SERVER_URL}/api/users/verify-token`, { token });
                    console.log(response.data.verified);

                    if (response.data.verified) {
                        setIsValidToken(true);
                    } else {
                        setIsValidToken(false);
                    }
                } catch (error) {
                    console.error('Error verifying token:', error);
                    setIsValidToken(false);
                }
            } else {
                setIsValidToken(false);
            }
        };

        verifyJwtToken();
    }, []);

    return isValidToken;
};