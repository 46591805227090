import React from 'react';
import {CZKCurrency} from "../../../utils/helper";

function UnAvailableColorBox({defaultMonthLabel}) {
    return (
        <div className={"unavailable-box d-flex flex-row justify-content-around align-items-center "}
             style={{width: "350px"}}>
            <div style={{backgroundColor: "red", width: "20px", height: "20px", marginRight: "auto"}}></div>
            <div className="px-3 py-2" style={{textAlign: "left", width: "330px"}}>JIž obsazeno, nelze
                rezervovat
            </div>
        </div>
    );
}

export default UnAvailableColorBox;