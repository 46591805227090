import React, {useEffect, useRef, useState} from 'react';
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin  from '@fullcalendar/daygrid' // a plugin!
import allLocales from '@fullcalendar/core/locales-all'
import axios from "axios";
import BasicModal from "../../../components/Modals/BasicModal";
import Navbar from "../../Navbar/Navbar";
import LoadingBar from "../../../components/LoadingBar/LoadingBar";
import Swal from "sweetalert2";
import "../../PageStyle.css"
import moment from "moment";
import {convertJsDateToPostgresFormat} from "../../../utils/helper";

function ReservationsOverView(props) {
    const [events, setEvents] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    const getAllCottageUserReservations = async () => {
        try {
            setIsLoading(true);
            let mainCottageFromDB = await axios.get(`${process.env.REACT_APP_SERVER_URL}/api/cottage/main-cottage`)
            setEvents([]);
            console.log(localStorage.getItem("token"))
            let mainCottageIdFromDB = mainCottageFromDB.data.mainCottage[0].cottage_id
            const response = await axios.get(`${process.env.REACT_APP_SERVER_URL}/api/admin/cottage/${mainCottageIdFromDB}/all-reservations`, {
                headers:{
                    "authorization" : `Bearer ${localStorage.getItem('token')}`
                }
            });

            if (events.length === 0) {
                response.data.cottageReservations.map((reservation) => {
                    const addedOneEndDate = moment(reservation.UserReservation.end_date, "YYYY-MM-DD").add(1, 'days')
                    const endDate = `${addedOneEndDate.year()}-${String(addedOneEndDate.month() + 1).padStart(2, '0')}-${String(addedOneEndDate.date()).padStart(2, '0')} 11:00:00`;
                    const startDate = `${convertJsDateToPostgresFormat(reservation.UserReservation.start_date)} 15:00:00`;

                    setEvents((prev) => [
                        ...prev,
                        {
                            title: reservation.Cottage.name,
                            start: startDate,
                            end: endDate,
                            priceForCottageOverAll: reservation.price_overall,
                            itemPricing: response.data.itemPricing,
                            userId: reservation.UserReservation.user_id,
                            firstname: reservation.UserReservation.User.firstname,
                            lastname: reservation.UserReservation.User.lastname,
                            email: reservation.UserReservation.User.email,
                            items: reservation.CottageItemReservation,
                            cottageReservationId: reservation.cottage_reservation_id,
                            wasDepositPaid: reservation.was_deposit_paid,
                            wasPaidFullAmount: reservation.was_paid_full_amount,
                            cottageItems: reservation.CottageItemReservations ? reservation.CottageItemReservations : null,
                            allDay: false,
                        }
                    ]);
                })
            }

        } catch (error) {
            console.error('Error while fetching data:', error);
        } finally {
            setIsLoading(false);
        }
    }

    console.log(events)
    //preventing for double rendering
    const effectRun = useRef(false);
    useEffect(() => {
        if (effectRun.current === false) {
            getAllCottageUserReservations();
        }
        return () => {
            effectRun.current = true;
        }
    }, []);

    const [showModal, setShowModal] = useState(false)
    const [modalData, setModalData] = useState([]);
    const openModal = (actualEvent) => { // bind with an arrow function
        setShowModal(true)
        setModalData(actualEvent);
    }
    const closeModal = () => {
        setShowModal(false)
    }

    const handleModalUpdate = async (updatedData) => {
        try {
            // Delete Reservation
            if (updatedData.status === "delete") {
                const updateEvent = events.filter(event => event.cottageReservationId !== updatedData.cottage_reservation_id)
                setEvents(updateEvent);
                try{
                    const response = await axios.put((`${process.env.REACT_APP_SERVER_URL}/api/admin/cottage-reservation/${updatedData.cottage_reservation_id}/delete-reservation`), {},{
                        headers:{
                            "authorization" : `Bearer ${localStorage.getItem('token')}`
                        }
                    })
                }catch(error) {
                    console.log(error)
                }
            } else { // Update Reservation Status
                const updateEvent = events.map(event => {
                    // Update object
                    if (event.cottageReservationId === updatedData.cottage_reservation_id) {
                        return {...event, wasDepositPaid: updatedData.was_deposit_paid, wasPaidFullAmount: updatedData.was_paid_full_amount};
                    }
                    return event;
                });
                setEvents(updateEvent);

                axios.post((`${process.env.REACT_APP_SERVER_URL}/api/admin/cottage-reservation/${updatedData.cottage_reservation_id}/confirm-reservation`), updatedData, {
                    headers:{
                        "authorization" : `Bearer ${localStorage.getItem('token')}`
                    }
                }).then((response) => {
                    console.log(response.status, response.data);
                });
            }
            await Swal.fire({
                icon: 'success',
                title: 'Hotovo',
                text: 'Záloha byla aktualizována.',
            })

            closeModal()
        } catch (error) {
            await Swal.fire({
                icon: 'error',
                title: 'Chyba',
                text: `${error.message}`,
            })
            console.error('Error while fetching data:', error);
        } finally {
        }
    }

    const [search, setSearch] = useState('')
    const searchReservation = (searchValue) => {
        const searchedReservation = events.filter(event => event.cottageReservationId === parseInt(searchValue))[0] ?? null
        openModal(searchedReservation)
        setSearch('')
    }
    return (
        <div className="">
            {
                isLoading ? (
                    <LoadingBar/>
                ) : (
                    <>
                        <Navbar/>
                        <div className="container front-page reservation_overview">
                            <h2>Rezervace</h2>

                            <div className={"my-4"}>
                                <input type={"text"} placeholder={"Vyhledat rezervaci podle ID rezervace"}
                                       className={"form-control"} onChange={(e) => setSearch(e.target.value)} value={search}/>
                                <button className={"rezervation-button"} onClick={() => {
                                    if(search) {
                                        searchReservation(search)
                                    }
                                }}>Vyhledat
                                </button>
                            </div>
                            <FullCalendar
                                plugins={[dayGridPlugin]}
                                locales={allLocales}
                                locale={'cs'}
                                initialView="dayGridMonth"
                                weekends={true}
                                lazyFetching={true}
                                events={events}
                                eventClick={(e) => {
                                    const currentEvent = events.filter(event => event.cottageReservationId === e.event._def.extendedProps.cottageReservationId)[0]
                                    openModal(currentEvent)
                                }}
                                eventContent={(arg) => {
                                    const element = document.createElement('div');
                                    element.innerHTML = arg.event._def.extendedProps.firstname
                                        + " " + arg.event._def.extendedProps.lastname
                                        + "<br>" + "ID Rezervace:" + arg.event._def.extendedProps.cottageReservationId

                                    if (arg.event._def.extendedProps.wasDepositPaid === true) {
                                        element.style.background = '#FFBF00';
                                    } if(arg.event._def.extendedProps.wasPaidFullAmount === true) {
                                        element.style.background = 'green';
                                    }if(!arg.event._def.extendedProps.wasDepositPaid && !arg.event._def.extendedProps.wasPaidFullAmount) {
                                        element.style.background = 'red';
                                    }
                                    return {domNodes: [element]};
                                }}
                                displayEventTime={false} // disable indicating 12a

                            />
                            <BasicModal
                                isOpen={showModal}
                                handleClose={() => setShowModal(false)}
                                data={modalData}
                                onUpdate={handleModalUpdate} // Date are send back from Basic Model and here is making request to API
                            />
                        </div>
                    </>
                )
            }
        </div>
    );
}

export default ReservationsOverView;
