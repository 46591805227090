import React from 'react';
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import {Link} from "react-router-dom";
import {formattedDate} from "../../utils/helper";

function ConfirmationModal({
                               show,
                               handleClose,
                               modalTitle,
                               modalText,
                               modalCloseText,
                               modalConfirmText,
                               redirectUrl,
                               startDate,
                               endDate,
                               rezervated
                           }) {
    return (
        <Modal
            show={show}
            onHide={handleClose}
            backdrop="static"
            keyboard={false}
        >
            <Modal.Header>
                <Modal.Title>{modalTitle}</Modal.Title>
            </Modal.Header>
            <Modal.Body className="d-flex flex-column align-items-center">
                {
                    rezervated ? (
                        <>
                            <p>Bohužel tento termín je již rezervovaný</p>
                        </>
                    ) : (
                        <>
                            <p>{modalText}</p>
                            <label className="text-end w-75">Začátek</label>
                            <div className="form-control w-75 m-1">{formattedDate(startDate)}</div>
                            <label className="text-end w-75">Konec</label>
                            <div className="form-control w-75">{formattedDate(endDate)}</div>
                        </>
                    )
                }
            </Modal.Body>
            <Modal.Footer>
                {
                    rezervated ? (
                        <>
                            <Button variant="danger" onClick={handleClose}>{modalCloseText}</Button>
                        </>
                    ): (
                        <>
                            <Button variant="danger" onClick={handleClose}>{modalCloseText}</Button>
                            <Link to={redirectUrl}>
                                <Button variant="success">
                                    {modalConfirmText}
                                </Button>
                            </Link>
                        </>
                    )
                }
            </Modal.Footer>
        </Modal>
    );
}

export default ConfirmationModal;