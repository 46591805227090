import React from 'react';
import {CZKCurrency} from "../../../utils/helper";

function AvailableColorBox({defaultMonthLabel}) {
    return (
        <div className={"default-month-box d-flex justify-content-around align-items-center"}
             style={{width: "350px"}}>
            <div style={{backgroundColor: "#06c285", width: "20px", height: "20px", marginRight: "auto"}}></div>
            <div className="px-3 py-2" style={{textAlign: "left", width: "330px"}}>
                {
                    defaultMonthLabel ? (
                        <>
                            <p className={"p-0 m-0"}>Volný termín</p>
                            <p className={"p-0 m-0"}>{defaultMonthLabel?.defaultMonthLabel}, minimální doba
                                pobytu jsou <strong>{defaultMonthLabel?.min_day_rent_length}</strong> noci</p>
                            <p className={"p-0 m-0"}>Cena za
                                noc: <strong>{CZKCurrency.format(defaultMonthLabel?.price)}</strong></p>
                        </>
                    ) : (
                        <p className={"p-0 m-0"}> Momentálně nejsou vypsány termíny</p>
                    )
                }
            </div>
        </div>
    );
}

export default AvailableColorBox;